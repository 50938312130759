<template>
    <Header activeTab="contact" isOpaque=true />
    <div class="full-page">
      <v-container fluid class="fill-height">
        <v-row
          justify="center"
          align="center"
          class="contact-background"
        >
          <v-col cols="12" md="6">
            <v-card class="pa-5 text-center" flat style="background-color: transparent; color: #fdeff9;">
              <h2 style="color: #ec38bc;">Contact Us</h2>
              <p>If you have any questions or need further assistance, feel free to reach out to us.</p>
              <p>Email us at:</p>
              <p style="color: #ec38bc;">
                <a href="mailto:contact@example.com" style="text-decoration: none; color: #ec38bc;">
                  contact@locality.media
                </a>
              </p>
  
              <p class="mt-4">Join our Discord community:</p>
              <p style="color: #ec38bc; word-break: break-all;">
                https://discord.gg/J8EybSUX4X 
              </p>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <Footer />
  </template>
  
  <script>
  import Header from '@/components/HeaderComponent.vue'; // Adjust the path as necessary
  import Footer from '@/components/Footer.vue';
  
  export default {
    components: {
      Header,
      Footer,
    },
  };
  </script>
  
  <style scoped>
  .full-page {
    height: 100vh;
    width: 100vw;
    overflow: hidden; /* Prevent scrolling */
    background: linear-gradient(to bottom, #03001e, #7303c0); /* Gradient background */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .fill-height {
    height: 100%;
    width: 100%;
  }
  
  /* Ensuring the container takes full height on smaller devices */
  @media (max-width: 600px) {
    .full-page {
      height: 100vh;
      width: 100vw;
    }
  }
  </style>
  