<template>
  <div class="min-h-screen bg-gray-50">

    <!-- Hero Section -->
    <header class="bg-gradient-to-r from-blue-900 to-blue-700 text-white py-16 px-4">
      <div class="max-w-6xl mx-auto text-center">
        
        <div class="breadcrumb-content">
            <span class="prompt-symbol">$</span>
            <router-link to="/consulting">consulting</router-link>
            <span class="prompt-symbol">/</span>
            <router-link to="/services">services</router-link>
            <span class="prompt-symbol">/</span>
            <span class="current-path">{{ title }}</span>
        </div>
        <h1 class="text-4xl md:text-5xl font-bold mb-4">Cybersecurity Audit</h1>
        <p class="text-xl opacity-90 max-w-3xl mx-auto">
          Protect your business from the growing threats of cyberattacks while ensuring full compliance with EU regulations.
        </p>
      </div>
    </header>

    <main class="max-w-6xl mx-auto px-4 py-12">
      <!-- Challenge Section -->
      <section class="bg-white rounded-lg shadow-sm p-8 mb-8">
        <h2 class="text-2xl font-bold text-blue-900 mb-6">The Challenge</h2>
        <p class="text-gray-700 mb-6">
          Cyber threats are more prevalent than ever, and small to mid-sized businesses are increasingly targeted. Without strong protection, businesses face financial loss, reputational damage, and even bankruptcy.
        </p>
        <div class="grid md:grid-cols-3 gap-6">
          <div v-for="(impact, index) in impacts" :key="index" 
               class="bg-gray-50 p-6 rounded-lg border border-gray-100">
            <div class="text-red-500 mb-2">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
              </svg>
            </div>
            <h3 class="font-semibold text-lg mb-2">{{ impact.company }}</h3>
            <p class="text-gray-600">{{ impact.impact }}</p>
          </div>
        </div>
        <DramaticCases />
      </section>

      <CybersecurityNews />
      <!-- Three Pillars Section -->
      <section class="bg-white rounded-lg shadow-sm p-8 mb-8">
        <h2 class="text-2xl font-bold text-blue-900 mb-6">Three Pillars of Protection</h2>
        <div class="grid md:grid-cols-3 gap-6">
          <div v-for="(pillar, index) in pillars" :key="index"
               class="text-center p-6 bg-blue-50 rounded-lg">
            <div class="text-blue-600 mx-auto mb-4">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
              </svg>
            </div>
            <h3 class="font-semibold text-lg mb-2">{{ pillar }}</h3>
          </div>
        </div>
      </section>

      <!-- Program Toggle -->
      <div class="bg-white rounded-lg shadow-sm p-6 mb-8 text-center">
        <div class="flex items-center justify-center gap-4 mb-4">
          <span :class="{'text-blue-600 font-semibold': !isExcellenceProgram}">
            Standard Program
          </span>
          <label class="relative inline-flex items-center cursor-pointer">
            <input 
              type="checkbox" 
              v-model="isExcellenceProgram" 
              class="sr-only peer"
            >
            <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all peer-checked:bg-blue-900"></div>
          </label>
          <span :class="{'text-blue-600 font-semibold': isExcellenceProgram}">
            Excellence Program
          </span>
        </div>
        <h2 class="text-2xl font-bold text-blue-900 mb-2">{{ programLabel }}</h2>
        <p class="text-xl font-semibold text-blue-600">{{ priceRange }}</p>
      </div>
      <p v-if="!isExcellenceProgram" class="text-xl font-semibold text-blue-600">
        Das Cypersecurity Audit gibt Ihnen einen klaren Weg vor, wie Sie Ihr Unternehmen vor Cyberattacken und Datenverlust schützen können.
      </p>
      <p v-if="isExcellenceProgram" class="text-xl font-semibold text-blue-600">
        Mit dem Cypersecurity Excellence Programm ist Ihr Unternehmen vollständig gegen Datenlecks und Informationsverlust geschützt. 
      </p>

      <!-- Timeline and Deliverables -->
      <div class="grid md:grid-cols-2 gap-8 mb-8">
        <!-- Timeline -->
        <section class="bg-white rounded-lg shadow-sm p-8">
          <h2 class="text-2xl font-bold text-blue-900 mb-6">Implementation Timeline</h2>
          <div class="space-y-6">
            <div v-for="(step, index) in currentTimeline" :key="index" 
                 class="flex items-start gap-4">
              <div class="flex-shrink-0 w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center text-blue-600">
                {{ step.week.split(' ')[1] }}
              </div>
              <div>
                <h3 class="font-semibold">{{ step.week }}</h3>
                <p class="text-gray-600">{{ step.task }}</p>
              </div>
            </div>
          </div>
        </section>

        <!-- Deliverables -->
        <section class="bg-white rounded-lg shadow-sm p-8">
          <h2 class="text-2xl font-bold text-blue-900 mb-6">Deliverables</h2>
          <ul class="space-y-4">
            <li v-for="(item, index) in currentDeliverables" :key="index"
                class="flex items-center gap-3 text-gray-700">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-green-500 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
              </svg>
              {{ item }}
            </li>
          </ul>
        </section>
      </div>

      <!-- Upsells -->
      <section v-if="!isExcellenceProgram"  class="bg-white rounded-lg shadow-sm p-8 mb-8">
        <h2 class="text-2xl font-bold text-blue-900 mb-6">Opportunities for Further Growth</h2>
        <div class="grid md:grid-cols-2 gap-6">
          <div class="p-6 bg-blue-50 rounded-lg">
            <h3 class="font-semibold text-lg mb-2">Cybersecurity Dashboard</h3>
            <p class="text-gray-600 mb-2">Monitor DDoS attacks, server logs and backups.</p>
            <p class="text-blue-600 font-semibold">+€10,000</p>
          </div>
          <div class="p-6 bg-blue-50 rounded-lg">
            <h3 class="font-semibold text-lg mb-2">Advanced Security Fixes</h3>
            <p class="text-gray-600">Expert support to implement security improvements beyond training</p>
          </div>
        </div>
      </section>

    </main>
    <SecurityFAQ />

   <p class="text-2xl font-bold text-blue-900" style="margin: 0 auto; 
  vertical-align: middle;
  margin-top: 25px;
  margin-bottom: 25px;
  text-align: center;">
    Contact us today for a personalized consultation via email at cybersecurity@locality.media.

   </p>

    <!-- Footer -->
    <footer class="bg-blue-900 text-white py-8 px-4">
      <div class="max-w-6xl mx-auto text-center">
        <p class="max-w-2xl mx-auto">
          Let us help you safeguard your business and ensure your compliance with EU regulations. Contact us today for a personalized consultation via email at cybersecurity@locality.media.
        </p>
      </div>
    </footer>
    <a href="/impressum" style="position: absolute; bottom: 20px; left: 20px; color: white;">Impressum</a>


  </div>
</template>

<script>
  import SecurityFAQ from './cybersecurity_audit/SecurityFAQ.vue'
  import DramaticCases from './cybersecurity_audit/DramaticCases.vue'
  import CybersecurityNews from './cybersecurity_audit/CybersecurityNews.vue'

export default {
  components: {
    SecurityFAQ,
    DramaticCases,
    CybersecurityNews
  },
  data() {
  return {
    isExcellenceProgram: false,
    impacts: [
      { company: '60%', impact: 'der KMU gehen innerhalb von 6 Monaten nach einem Ransomware-Angriff bankrott.' },
      { company: '4,34 Mio', impact: 'sind die durchschnittlichen Kosten eines Datenlecks im Jahr 2023.' },
      { company: '15-20%', impact: 'ihres IT-Budgets sollten KMU für Cybersecurity ausgeben.' }
    ],
    pillars: [
      'Sicherstellung der Geschäftskontinuität',
      'Schutz sensibler Informationen',
      'Vertrauen mit Stakeholdern aufbauen'
    ],
    standardTimeline: [
      { week: 'Woche 1', task: 'Geschäftssicherheitsbewertung' },
      { week: 'Woche 2', task: 'Schwachstellen- und Penetrationstests' },
      { week: 'Woche 3', task: 'Mitarbeiterschulung' },
      { week: 'Woche 4-8', task: 'Sicherheits-Empfehlungen, Berichterstellung und fortlaufender Support' }
    ],
    excellenceTimeline: [
      { week: 'Woche 1-2', task: 'Umfassende Sicherheits- und Compliance-Bewertung' },
      { week: 'Woche 3-4', task: 'Erweiterte Schwachstellentests und Bedrohungsmodellierung' },
      { week: 'Woche 5-6', task: 'Entwicklung eines maßgeschneiderten Sicherheitsrahmens' },
      { week: 'Woche 7-8', task: 'Erweiterte Mitarbeiterschulung und Zertifizierung' },
      { week: 'Woche 9-12', task: 'Implementierungsunterstützung und Einrichtung der kontinuierlichen Überwachung' }
    ],
    standardDeliverables: [
      'Locality Silber Security Zertifizierung (einschließlich ISO 27001),',
      'Umfassender Bericht zur Schwachstellenbewertung,',
      'Im Anschluss Cyberdefense Excellence Program vergünstigt möglich,',
      'Detaillierter OpSec-Bericht mit Erkenntnissen und Empfehlungen,',
      'Penetrationstests; Ausnutzung bekannter Schwachstellen, SQL-Injection-Versuche, Phishing-Versuche,',
      'Bericht zur Risikobewertung der Zugangskontrolle,',
      'Zugang zum Locality High Security Newsletter,',
      'DSGVO-Konformität,',
      'Code-Überprüfung; Sicherstellung der korrekten Verwendung von Verschlüsselungsmethoden und Bibliotheken,',
      'Team-Schulung zur Sensibilisierung für Sicherheitsrisiken,',
      'Fortlaufender Support und Überwachung.'
    ],
    excellenceDeliverables: [
      'Locality Gold Security Zertifizierung (einschließlich ISO 27001),',
      'Cybersecurity-Dashboard zur Überwachung von DDoS-Angriffen und Backups,',
      'Penetrationstests; Ausnutzung bekannter Schwachstellen, SQL-Injection-Versuche, Phishing-Versuche,',
      'Umsetzung der Zugangskontrolle,',
      'Umfassender Bericht zur Schwachstellenbewertung,',
      'Detaillierter OpSec-Bericht mit Erkenntnissen und Empfehlungen,',
      'DSGVO-Konformität,',
      'Code-Überprüfung und -Refaktorisierung; Sicherstellung der korrekten Verwendung von Verschlüsselungsmethoden und Bibliotheken,',
      'Team-Schulung zur Sensibilisierung für Sicherheitsrisiken,',
      'Implementierung erweiterter Verschlüsselungs- und Sicherheitsmaßnahmen,',
      'Implementierung von Datensicherungssystemen,',
      'AuthFlow-Überprüfung; Sicherstellung der korrekten Nutzung von Authentifizierungsflüssen.'
    ]
  }
},
  computed: {
    programLabel() {
      return this.isExcellenceProgram
        ? "CyberDefense Excellence Program"
        : "Cybersecurity & Compliance Solutions"
    },
    priceRange() {
      return this.isExcellenceProgram
        ? "Ab €35,000"
        : "Ab €10,000"
    },
    currentTimeline() {
      return this.isExcellenceProgram ? this.excellenceTimeline : this.standardTimeline
    },
    currentDeliverables() {
      return this.isExcellenceProgram ? this.excellenceDeliverables : this.standardDeliverables
    }
  }
}
</script>

<style>

.breadcrumb-content {
  @apply p-4 flex items-center gap-2 font-mono text-sm;
}

.breadcrumb-content a {
  @apply text-cyan-400 hover:text-cyan-300 transition-colors duration-200;
}

</style>