<template>
    <div>
      <Header :activeTab="'ForInvestors'" :isOpaque="true" background="true"/>   
       <div style="height: 20px"></div>
      <ForInvestors />
      <Footer fillColor="#ffffff" />
    </div>
  </template>
  
  <script>
  import Header from '@/components/HeaderComponent.vue';
  import ForInvestors from '@/views/ForInvestors.vue';
  import Footer from '../components/Footer.vue';
  
  export default {
    name: 'ForInvestorsPage',
    components: {
      Header,
      ForInvestors,
      Footer
    }
  };
  </script>
  
  <style>
  .DOCU {
    color:red;
  }
  /* You can add some styles here if needed */
  </style>
  