<template>
  <div class="all">
    <div class="curve">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" preserveAspectRatio="none">
        <defs>
            <linearGradient id="gradient2" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" style="stop-color:#202223; stop-opacity:1" />
                <stop offset="100%" style="stop-color:#000000; stop-opacity:1" />
            </linearGradient>
        </defs>
        <path fill="url(#gradient2)" d="M0,128L48,144C96,160,192,192,288,192C384,192,480,160,576,154.7C672,149,768,171,864,171.3C960,171,1056,149,1152,144C1248,139,1344,149,1392,154.7L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
    </svg>
  </div>
  <div class="flex items-center justify-center h-screen w-screen bg-black text-purple-700">
    <div class="p-5 text-left">
      <h1 class="text-4xl font-normal text-white">
        How many<br>
        <span class="text-purple-500">
          developer hours<br>
        </span>
        would you need to create a<br>
        <span class="text-purple-500">
          highly scalable, end-to-end-encrypted<br>
        </span>
        Messenger Application?<br>
      </h1>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  name: 'PresentationSlide0',
}
</script>

<style scoped>
.all {    
  margin-top: -300px; /* Move the content up by 300px */


}
.curve {
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.curve svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 330px; /* Increased height of the curve */
}
</style>