<template>
  <div>
    <header-component :isOpaque="true"></header-component>
    <v-container class="fixed-selection-bar">
      <v-row>
        <v-col>
          <v-select
            v-model="selectedFilter"
            :items="filters"
            label="Select Filter"
          ></v-select>
        </v-col>
        <v-col>
          <v-menu
            v-model="menuStartDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="startDate"
                label="Start Date"
                prepend-icon="event"
                readonly
                v-bind="attrs"
                v-on="on"
                @click="menuStartDate = true"
              ></v-text-field>
            </template>
            <v-date-picker v-model="startDate" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menuStartDate = false">Cancel</v-btn>
              <v-btn text color="primary" @click="menuStartDate = false">OK</v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col>
          <v-menu
            v-model="menuEndDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="endDate"
                label="End Date"
                prepend-icon="event"
                readonly
                v-bind="attrs"
                v-on="on"
                @click="menuEndDate = true"
              ></v-text-field>
            </template>
            <v-date-picker v-model="endDate" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menuEndDate = false">Cancel</v-btn>
              <v-btn text color="primary" @click="menuEndDate = false">OK</v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col>
          <v-text-field v-model="channelFilter" label="Channel Filter"></v-text-field>
        </v-col>
        <v-col>
          <v-text-field v-model="eventFilter" label="Event Filter"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-btn @click="applyFilters">Apply Filters</v-btn>
      </v-row>
    </v-container>
    <div class="chart-container">
      <Bar
        v-if="chartData"
        :data="chartData"
        :options="chartOptions"
      />
      <div v-else>Loading chart data...</div>
    </div>
  </div>
</template>

<script>
import { Bar } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';
import HeaderComponent from '../components/HeaderComponent.vue';
import axios from 'axios';
import { format, startOfDay, endOfDay, startOfWeek, endOfWeek, startOfHour, endOfHour, eachHourOfInterval, eachDayOfInterval, eachWeekOfInterval } from 'date-fns';
import { subWeeks } from 'date-fns'; // Import the subWeeks function

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

export default {
  name: 'EventsView',
  components: { Bar, HeaderComponent },
  data() {
    return {
      chartData: null,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
      filters: ['hourly', 'daily', 'weekly'],
      selectedFilter: 'daily',
      startDate: subWeeks(new Date(), 1), // Set startDate to one week behind
      endDate: new Date(),
      channelFilter: '',
      eventFilter: '',
      events: [],
      menuStartDate: false,
      menuEndDate: false,
    };
  },
  methods: {
    async fetchData() {
      try {
        const appId = this.$route.params.app_id;
        const response = await axios.get(`http://localhost:4000/api/developer/view/${appId}`);
        this.events = response.data;
        this.applyFilters();
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
    applyFilters() {
      const filteredEvents = this.events.filter(event => {
        const eventDate = new Date(event.timestamp);
        const isInRange = eventDate >= startOfDay(new Date(this.startDate)) && eventDate <= endOfDay(new Date(this.endDate));
        const isChannelMatch = this.channelFilter ? event.channel.includes(this.channelFilter) : true;
        const isEventMatch = this.eventFilter ? event.event.includes(this.eventFilter) : true;
        return isInRange && isChannelMatch && isEventMatch;
      });

      const groupedEvents = this.groupEvents(filteredEvents, this.selectedFilter);

      this.chartData = {
        labels: Object.keys(groupedEvents),
        datasets: [
          {
            label: 'Event Count',
            backgroundColor: '#f87979',
            data: Object.values(groupedEvents),
          },
        ],
      };
    },
    groupEvents(events, filter) {
      const grouped = {};
      const interval = [];

      switch (filter) {
        case 'hourly':
          interval.push(...eachHourOfInterval({ start: new Date(this.startDate), end: new Date(this.endDate) }));
          break;
        case 'daily':
          interval.push(...eachDayOfInterval({ start: new Date(this.startDate), end: new Date(this.endDate) }));
          break;
        case 'weekly':
          interval.push(...eachWeekOfInterval({ start: new Date(this.startDate), end: new Date(this.endDate) }));
          break;
      }

      interval.forEach(date => {
        let key;
        switch (filter) {
          case 'hourly':
            key = format(startOfHour(date), 'PPPP HH:mm');
            break;
          case 'daily':
            key = format(startOfDay(date), 'PPPP');
            break;
          case 'weekly':
            key = format(startOfWeek(date), 'PPPP');
            break;
        }
        grouped[key] = 0;
      });

      events.forEach(event => {
        let key;
        const date = new Date(event.timestamp);

        switch (filter) {
          case 'hourly':
            key = format(startOfHour(date), 'PPPP HH:mm');
            break;
          case 'daily':
            key = format(startOfDay(date), 'PPPP');
            break;
          case 'weekly':
            key = format(startOfWeek(date), 'PPPP');
            break;
        }

        if (!grouped[key]) {
          grouped[key] = 0;
        }
        grouped[key]++;
      });

      return grouped;
    },
  },
  async mounted() {
    await this.fetchData();
  },
};
</script>

<style scoped>
.fixed-selection-bar {
  position: fixed;
  top: 100px; /* Add distance to the top bar */
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: white;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.chart-container {
  margin-top: 250px; /* Adjust margin to make space for the fixed selection bar */
  padding-top: 32px; /* Add space between the selectors and the chart */
  height: 400px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 16px;
}

.table-container {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
}

.button-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 16px;
}
</style>
