<template>
    <div>
      <Header :activeTab="'documentation'" :isOpaque="false" />
      <Header :activeTab="'documentation'" :isOpaque="false" />
      <Documentation />
      <Footer />
    </div>
  </template>
  
  <script>
  import Header from '@/components/HeaderComponent.vue';
  import Documentation from '@/views/Documentation.vue';
  import Footer from '../components/Footer.vue';
  
  export default {
    name: 'DocumentationPage',
    components: {
      Header,
      Documentation,
      Footer
    }
  };
  </script>
  
  <style>
  .DOCU {
    color:red;
  }
  /* You can add some styles here if needed */
  </style>
  