<template>
  <div class="flex flex-col lg:flex-row bg-black w-full h-full">
    <div class="lg:w-1/2 h-full">
      <img :src="require('@/assets/elonso.jpg')" alt="Image" class="object-cover h-full w-full" />
    </div>
    <!-- Left Side - Text Content -->
    <div class="lg:w-1/2 flex flex-col justify-center p-8 text-white">
      <h1 class="text-4xl font-bold mb-4">This is Elonzo</h1>
      <p class="leading-normal text-base md:text-2xl mb-8 text-left content">
        As a developer, he fought 
      </p>
    </div>
    <!-- Right Side - Image -->
    
  </div>
</template>

<script>
export default {
  name: 'PresentationSlide3'
};
</script>

<style scoped>
/* Add any additional styling here */
.content {
  padding: 20px;
  text-align: left;
}
</style>
