<template>
  <div class="flex flex-col lg:flex-row bg-black w-full h-full">
    <!-- Left Side - Text Content -->
    <div class="lg:w-1/2 flex flex-col justify-center p-8 text-gray-300" style="margin-top: 60px;">
      <h1 class="text-4xl font-bold mb-4">This is Tessa</h1>
      <p class="text-left text-2xl">
        <span class="text-purple-500">Tessa says,</span>
        just the <span class="text-purple-500">different messengers</span> and the fact that you cannot 
        <span class="text-purple-500">message different messengers</span> creates <span class="text-purple-500">division</span>.

        <br><br>
        What if <span class="text-purple-500">all messengers</span> could <span class="text-purple-500">message each other</span>?
        <br>
        What if I did not have to <span class="text-purple-500">add my friends in each app</span> that I use, in each game?
        <br><br>
        What if I could <span class="text-purple-500">keep my old messages</span>?
      </p>
    </div>
    <!-- Right Side - Image -->
    <div class="lg:w-1/2 h-full">
      <img :src="require('@/assets/karina-tess.jpg')" alt="Image" class="object-cover h-full w-full" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PresentationSlide2'
};
</script>

<style scoped>
/* Add any additional styling here */
</style>
