<template>
    <ServiceLayout
      title="Private Cloud Infrastructure"
      subtitle="Enterprise-grade cloud infrastructure with unparalleled security and scalability"
    >
      <div class="service-grid">
        <div class="feature-card">
          <h2>Infrastructure Features</h2>
          <ul>
            <li>99.9999999% availability guarantee</li>
            <li>Global data center distribution</li>
            <li>Automatic failover and disaster recovery</li>
            <li>Real-time monitoring and analytics</li>
            <li>Advanced threat detection</li>
          </ul>
        </div>
  
        <div class="feature-card">
          <h2>Technical Specifications</h2>
          <ul>
            <li>Custom hardware configuration</li>
            <li>Enterprise-grade encryption</li>
            <li>Dedicated fiber connections</li>
            <li>Load balancing and auto-scaling</li>
            <li>24/7 technical support</li>
          </ul>
        </div>
  
        <div class="feature-card pricing">
          <h2>Investment</h2>
          <div class="price-range">€1M - €5M</div>
          <p>Enterprise-grade solution with custom configuration</p>
          <ul>
            <li>Hardware costs included</li>
            <li>Installation and setup</li>
            <li>Ongoing maintenance</li>
            <li>Staff training</li>
          </ul>
        </div>
      </div>
  
      <div class="comparison-table">
        <h2>Cost Comparison</h2>
        <table>
          <thead>
            <tr>
              <th>Metric</th>
              <th>Public Cloud</th>
              <th>Private Cloud</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Monthly Cost at Scale</td>
              <td>€500,000+</td>
              <td>€50,000</td>
            </tr>
            <tr>
              <td>Data Transfer</td>
              <td>Pay per GB</td>
              <td>Unlimited</td>
            </tr>
            <tr>
              <td>Storage</td>
              <td>Pay per GB</td>
              <td>Unlimited</td>
            </tr>
          </tbody>
        </table>
      </div>
    </ServiceLayout>
  </template>
  
  <script>
import ServiceLayout from '@/components/ServiceLayout.vue'
  
  export default {
    name: 'PrivateCloud',
    components: {
      ServiceLayout
    }
  }
  </script>
  
  <style scoped>
  .service-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 30px;
    margin-bottom: 60px;
  }
  
  .feature-card {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    padding: 30px;
    backdrop-filter: blur(10px);
  }
  
  .feature-card h2 {
    color: #ec38bc;
    margin-bottom: 20px;
    font-size: 24px;
  }
  
  .feature-card ul {
    list-style-type: none;
    padding: 0;
  }
  
  .feature-card li {
    margin: 15px 0;
    padding-left: 25px;
    position: relative;
  }
  
  .feature-card li::before {
    content: "→";
    position: absolute;
    left: 0;
    color: #ec38bc;
  }
  
  .timeline {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .timeline-item {
    padding-left: 20px;
    border-left: 2px solid #ec38bc;
  }
  
  .timeline-item h3 {
    color: #ec38bc;
    margin-bottom: 5px;
  }
  
  .pricing {
    text-align: center;
  }
  
  .price-range {
    font-size: 32px;
    color: white;
    margin: 20px 0;
  }
  
  .comparison-table {
    margin-top: 60px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  th, td {
    padding: 15px;
    text-align: left;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  
  th {
    background: rgba(236, 56, 188, 0.1);
    color: #ec38bc;
  }
  </style>