<template>
  <div class="documentation">
    <div class ="content">
      <h1>Core Library</h1>
      <h2>LocalitySocialCloud, LoggedInUser</h2>
      <p>
        Locality Social Cloud handles user authentication for you.<br>
        Each user receives an ECDH-key based on the elliptic curve M-511.<br>
        This key can be restored even on other devices.<br>
        It can be used to compute shared keys with other users based on ECDH.
      </p>
      <br><br>
      <h2>PubSub, LocalityEvent</h2>
      <p>
        PubSub is a mixin you can use in your classes to emit events to addresses with the following guarantee: <br>
        Each PubSub processes each event that was sent to its topic exactly once.<br>
        Events you can send are a json payload. LocalityEvent encapsulates this payload with additional metadata like two timestamps and a uuid.<br>
      </p>
      <br>
      <p>
        <b>Timeline</b><br>
              --- String getTopic();<br>
              --- void onReceive(LocalityEvent localityEvent);<br>
              --- WaitingMessage send(String event, Map&ltString,dynamic&gt payload);<br>
      </p>
      <br>
      <p>
        The methods 'getTopic' and 'onReceive' will have to be implemented by you, while the 'Timeline' and 'send' method are given to you. <br>
        <br>Generally the recommended pattern of development for many problems is:<br>
        <ul class="styled-list">
          <li>
              <strong>1) PubSub:</strong> The PubSub is generally a <strong>ViewModel</strong> and <strong>Controller</strong> at the same time. Often it extends <strong>ThrottledChangeNotifier</strong> (more on that later).
          </li>
          <li>
              <strong>2) Supervisor:</strong> If you need many PubSubs of the same type, you generally create a <strong>Supervisor</strong> object that keeps track of the many PubSubs it opens (or closes).
          </li>
          <li>
              <strong>3) Conditional Events:</strong> If you work with conditional events, where some depend on others, use <strong>Timeline</strong> and <strong>EventWaiter</strong> (more on that later).
          </li>
        </ul>
      </p>
      <br><br>
      <h2>Timeline, OrderedTimeline, WaitingEvents</h2>
      <p>
        If some events depend on others, you can work with these classes to simplify development.<br>
        Timeline lets you wait for synchronization between the local and global Timeline of a PubSub, before executing functions. When Locality Social Cloud connects to the server, it fetches state synchronization updates from the server. Methods in Timeline.whenSynchronized will be called shortly after all synchronization updates from the server (or Cache, if you use a cache) have been processed. The only method you need from timeline is
        WaitingEvents holds events back from being processed until other events with a shared key in the payload come along.
      </p>
      <br><br><br>
      <div>You can check out the full documentation in the <a style="color: #09a9ff; font-size: 37px; margin-left: 15px;"
         href="https://pub.dev/packages/locality_social_cloud">Flutter Package</a>.</div>
      </div>

    <div class ="content">
      <h1>More Libraries...</h1>
      <div>If you want to add friends, check out the <br>
        <a style="color: #09a9ff; font-size: 30px;"
         href="https://pub.dev/packages/locality_social_cloud_friendlist">FriendList Package
        </a>.
      </div>
      <br>
      <div>If you want to send end-to-end-encrypted messages, check out the <br>
        <a style="color: #09a9ff; font-size: 30px;"
         href="https://pub.dev/packages/locality_social_cloud_chat">Messaging Package
        </a>.
      </div>
    </div>
  </div>
</template>

<script>

import Header from '@/components/HeaderComponent.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Documentation',
  components: {
    Header,
    Footer
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap');

.content {
  margin-top: 120px;
}
h1 {
  font-size: 70px;
}
h2 {
  font-size: 32px;
}
p {
  font-size: 16px;
}
.documentation {
  background: linear-gradient(to bottom, #414345, #414345 10%);
  height: 100%;
  color: #bbb1b8;
  padding: 50px;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.3rem;
}
li {
  margin-top: 20px;
}

</style>