<template>
  <div class="cybersecurity-news">
    <h2 class="title text-blue-900">Cybersecurity News</h2>
    
    <!-- Loading state -->
    <div v-if="loading" class="loading">
      Loading news...
    </div>

    <!-- Error state -->
    <div v-if="error" class="error">
      {{ error }}
    </div>

    <!-- Horizontal scrollable news list -->
    <div class="news-container" v-if="!loading && !error">
      <div class="scroll-button left" @click="scroll('left')" v-show="canScrollLeft">
        &lt;
      </div>
      
      <div class="news-scroll" ref="newsScroll">
        <div v-for="(article, index) in news" :key="index" class="news-card">
          <img 
            v-if="article.image" 
            :src="article.image" 
            :alt="article.title"
            @error="handleImageError"
          >
          <div class="news-content">
            <h3>{{ article.title }}</h3>
            <p>{{ article.description }}</p>
            <div class="news-meta">
              <span>{{ formatDate(article.publishedAt) }}</span>
              <a :href="article.url" target="_blank" rel="noopener noreferrer">Read More</a>
            </div>
          </div>
        </div>
      </div>

      <div class="scroll-button right" @click="scroll('right')" v-show="canScrollRight">
        &gt;
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'CybersecurityNews',
  
  data() {
    return {
      news: [],
      loading: true,
      error: null,
      canScrollLeft: false,
      canScrollRight: true,
    }
  },

  async created() {
    await this.fetchNews();
  },

  mounted() {
    this.$nextTick(() => {
      if (this.$refs.newsScroll) {
        this.checkScrollButtons();
        this.$refs.newsScroll.addEventListener('scroll', this.checkScrollButtons);
      }
    });
  },

  beforeDestroy() {
    // Remove scroll event listener
    this.$refs.newsScroll.removeEventListener('scroll', this.checkScrollButtons);
  },

  methods: {
    async fetchNews() {
      try {
        // Replace with your actual GNews API key
        const API_KEY = '4b2396c75d20793a207509aae3940442';
        const response = await axios.get(`https://gnews.io/api/v4/search`, {
          params: {
            q: 'cyberangriff',
            lang: 'de',
            max: 10,
            token: API_KEY
          }
        });
        
        this.news = response.data.articles;
      } catch (err) {
        this.error = 'Failed to fetch news. Please try again later.';
        console.error('Error fetching news:', err);
      } finally {
        this.loading = false;
      }
    },

    formatDate(dateString) {
      return new Date(dateString).toLocaleDateString();
    },

    scroll(direction) {
      const container = this.$refs.newsScroll;
      const scrollAmount = container.clientWidth * 0.8;
      
      if (direction === 'left') {
        container.scrollBy({
          left: -scrollAmount,
          behavior: 'smooth'
        });
      } else {
        container.scrollBy({
          left: scrollAmount,
          behavior: 'smooth'
        });
      }
    },

    checkScrollButtons() {
      const container = this.$refs.newsScroll;
      this.canScrollLeft = container.scrollLeft > 0;
      this.canScrollRight = container.scrollLeft < (container.scrollWidth - container.clientWidth);
    },

    handleImageError(e) {
      e.target.src = 'path/to/fallback/image.jpg'; // Replace with your fallback image
    }
  }
}
</script>

<style scoped>
.cybersecurity-news {
  width: 100%;
  padding: 20px;
  position: relative;
}

.title {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
}

.news-container {
  position: relative;
  display: flex;
  align-items: center;
}

.news-scroll {
  display: flex;
  overflow-x: scroll;
  scroll-behavior: smooth;
  -ms-overflow-style: none;  /* Hide scrollbar for IE and Edge */
  scrollbar-width: none;  /* Hide scrollbar for Firefox */
  gap: 20px;
  padding: 20px 0;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.news-scroll::-webkit-scrollbar {
  display: none;
}

.news-card {
  min-width: 300px;
  max-width: 300px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  background: white;
  overflow: hidden;
}

.news-card img {
  width: 100%;
  height: 160px;
  object-fit: cover;
}

.news-content {
  padding: 15px;
}

.news-content h3 {
  margin: 0 0 10px 0;
  font-size: 16px;
  line-height: 1.4;
}

.news-content p {
  font-size: 14px;
  color: #666;
  margin: 0 0 15px 0;
  line-height: 1.5;
}

.news-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  color: #888;
}

.news-meta a {
  color: #0066cc;
  text-decoration: none;
}

.news-meta a:hover {
  text-decoration: underline;
}

.scroll-button {
  position: absolute;
  width: 40px;
  height: 40px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.scroll-button:hover {
  background: rgba(255, 255, 255, 1);
}

.scroll-button.left {
  left: 10px;
}

.scroll-button.right {
  right: 10px;
}

.loading, .error {
  text-align: center;
  padding: 20px;
  color: #666;
}

.error {
  color: #dc3545;
}
</style>
