<template>
  <div class="max-w-7xl mx-auto px-4 py-12">
    <!-- Header Section -->
    <div class="text-center mb-16">
      <h1 class="text-4xl font-bold text-gray-900 mb-4">
        Social App Development
      </h1>
      <p class="text-xl text-gray-600">
        Build engaging digital communities that connect people worldwide
      </p>
    </div>

    <!-- Value Proposition -->
    <div class="bg-gradient-to-r from-blue-50 to-indigo-50 rounded-2xl p-8 mb-16">
      <p class="text-gray-700 leading-relaxed max-w-3xl mx-auto text-center">
        In today's interconnected world, social applications have become the cornerstone of modern communication and community building. Our social app development service transforms your vision into a vibrant digital platform that brings people together. We combine cutting-edge technology with intuitive design to create spaces where connections flourish and communities thrive.
      </p>
    </div>

    <!-- Service Grid -->
    <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
      <!-- Core Features Card -->
      <div class="bg-white rounded-xl shadow-lg p-6 hover:shadow-xl transition-shadow">
        <div class="flex items-center gap-2 mb-4">
          <div class="p-2 bg-blue-100 rounded-lg">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4" />
            </svg>
          </div>
          <h2 class="text-xl font-semibold text-gray-900">Core Features</h2>
        </div>
        <ul class="space-y-3 text-gray-600">
          <li v-for="feature in coreFeatures" :key="feature" class="flex items-center gap-2">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
            </svg>
            {{ feature }}
          </li>
        </ul>
      </div>

      <!-- Development Process Card -->
      <div class="bg-white rounded-xl shadow-lg p-6 hover:shadow-xl transition-shadow">
        <div class="flex items-center gap-2 mb-4">
          <div class="p-2 bg-purple-100 rounded-lg">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-purple-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
            </svg>
          </div>
          <h2 class="text-xl font-semibold text-gray-900">Development Process</h2>
        </div>
        <div class="space-y-4">
          <div v-for="(phase, index) in developmentProcess" :key="index" class="relative pl-8 border-l-2 border-purple-200 pb-4 last:pb-0">
            <div class="absolute -left-[9px] top-0 w-4 h-4 rounded-full bg-purple-500"></div>
            <h3 class="font-medium text-purple-600">{{ phase.time }}</h3>
            <p class="text-gray-600">{{ phase.description }}</p>
          </div>
        </div>
      </div>

      <!-- Investment Card -->
      <div class="bg-white rounded-xl shadow-lg p-6 hover:shadow-xl transition-shadow">
        <div class="flex items-center gap-2 mb-4">
          <div class="p-2 bg-green-100 rounded-lg">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-green-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </div>
          <h2 class="text-xl font-semibold text-gray-900">Investment</h2>
        </div>
        <div class="text-center mb-4">
          <div class="text-3xl font-bold text-gray-900 mb-2">€20K - €40K</div>
          <p class="text-gray-600">Scalable solution with future growth in mind</p>
        </div>
        <ul class="space-y-3 text-gray-600">
          <li v-for="benefit in investmentBenefits" :key="benefit" class="flex items-center gap-2">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
            </svg>
            {{ benefit }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SocialService',
  data() {
    return {
      coreFeatures: [
        'Robust user authentication system',
        'Real-time messaging and notifications',
        'Content sharing and management',
        'Advanced user profiles',
        'Privacy controls and moderation tools',
        'Analytics and engagement metrics'
      ],
      developmentProcess: [
        { time: 'Week 1-2', description: 'UX design and architecture planning' },
        { time: 'Week 3-4', description: 'Core features development' },
        { time: 'Week 5-6', description: 'Social features integration' },
        { time: 'Week 7-8', description: 'Testing and deployment' }
      ],
      investmentBenefits: [
        'Infrastructure setup included',
        'Performance optimization',
        '3 months of technical support',
        'Analytics dashboard'
      ]
    }
  }
}
</script>

<style scoped>
.hover\:shadow-xl:hover {
  transition-duration: 300ms;
}
</style>