<template>
  <div class="flex items-center justify-center w-screen bg-black text-white">
    <div class="p-5 text-left">
      <h1 class="text-4xl font-normal text-purple-500">
        Content is locked away in a closed ecosystem and grinded for data! <br>
      </h1>
      <h1 class="text-4xl font-normal text-green-500">
        Consumer applications are hard to make! <br>
      </h1>
      <h1 class="text-4xl font-normal text-yellow-500">
        Power how information flows is in the hand of a few! <br>
      </h1>
      <h1 class="text-4xl font-normal text-yellow-500">
        But information flow is literally at the very base of society. <br>
        If information flow degenerates, society degenerates.
      </h1>
      <h1 class="text-4xl font-normal text-orange-500">
        We must really do something against the upcoming Cyberpunk Dystopia! <br>
      </h1>
      <h1 class="text-4xl font-normal">
        We are <span class="text-purple-500">programmers, we are technocrats in a technocratic world <br></span>
        and if we do nothing, <span class="text-red-500">NOBODY WILL.</span>
      </h1> 
      <h1 class="text-4xl font-normal text-purple-500">
        This is a battle for the future of humanity and <span class="text-red-500">EVERYTHING</span> is on the line.
      </h1>
      <h1 class="text-4xl font-normal text-red-500">
        Take power now my warriors! Let us create a <span class="text-green-500">better tomorrow.</span>
      </h1> 
      <div class="mt-8 text-center">
        <a style="margin-top: 150px; margin-bottom: 250px;" href="/documentation" class="inline-block px-6 py-3 bg-indigo-500 text-white font-semibold text-xl rounded hover:bg-indigo-600">
          Start Developing
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PresentationSlide4',
}
</script>
