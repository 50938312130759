<template>
  <div class="careers">
    <div style="height: 200px;"></div>
    <h1>Join Our Team</h1>
    <p>
      We are on a mission to revolutionize the social media landscape and we’re looking for talented individuals to help us achieve our vision. Explore our open positions below!
    </p>

    <section class="job-listing">
      <h2>Business Developer</h2>
      <p>
        You will be responsible for driving marketing initiatives and leading sales efforts targeting developers.
      </p>
      <h3>Key Responsibilities:</h3>
      <ul>
        <li>Develop and implement marketing strategies to promote our products.</li>
        <li>Handle all business paperwork and legal documentation.</li>
        <li>Engage with developers to understand their needs and sell our solutions.</li>
      </ul>
      <h3>Required Strengths:</h3>
      <ul>
        <li>Strong background in marketing and business development.</li>
        <li>Excellent sales skills with a focus on the developer community.</li>
        <li>Proficient in handling administrative tasks and paperwork.</li>
      </ul>
    </section>

    <section class="job-listing">
      <h2>Performance Metrics Engineer</h2>
      <p>
        We are seeking a Performance Metrics Engineer with a strong academic background in mathematics or informatics. You will be responsible for measuring the performance of our system.
      </p>
      <h3>Key Responsibilities:</h3>
      <ul>
        <li>Measure performance of our applications.</li>
        <li>Automate performance analytics metrics.</li>
        <li>Collaborate with Web Developer to build Analytics page.</li>
      </ul>
      <h3>Required Strengths:</h3>
      <ul>
        <li>Strong knowledge about performance and scalability.</li>
        <li>Knowledge of performance measurement tools or willing to learn.</li>
        <li>Knowledge of distributed systems preferably or willing to learn.</li>
        <li>Knowledge of Erlang or willing to learn.</li>
      </ul>
    </section>

    <section class="job-listing">
      <h2>Highscale Developer</h2>
      <p>
        We are seeking a Highscale Developer with a strong academic background in mathematics. You will work on our scalable applications, ensuring high performance and reliability.
      </p>
      <h3>Key Responsibilities:</h3>
      <ul>
        <li>Design and implement scalable applications.</li>
        <li>Optimize system performance and reliability.</li>
        <li>Collaborate with the team to develop innovative solutions.</li>
      </ul>
      <h3>Required Strengths:</h3>
      <ul>
        <li>Strong understanding of academic mathematics.</li>
        <li>Experience in scalable application development.</li>
        <li>Ability to solve complex mathematical problems in coding.</li>
      </ul>
    </section>

    <section class="job-listing">
      <h2>Flutter App Developer</h2>
      <p>
        We are looking for a skilled Flutter App Developer to build beautiful and performant mobile applications. Your expertise in Flutter will be crucial in delivering an exceptional user experience.
      </p>
      <h3>Key Responsibilities:</h3>
      <ul>
        <li>Develop and maintain mobile applications using Flutter.</li>
        <li>Collaborate with designers to create engaging user interfaces.</li>
        <li>Implement app features based on client and team feedback.</li>
      </ul>
      <h3>Required Strengths:</h3>
      <ul>
        <li>Proficient in Flutter and Dart programming.</li>
        <li>Experience with mobile app development lifecycle.</li>
        <li>Strong understanding of UI/UX principles.</li>
      </ul>
    </section>

    <section class="job-listing">
      <h2>Social Media Marketing Manager</h2>
      <p>
        As our Social Media Marketing Manager, you will create and execute social media strategies to enhance our brand presence and engagement. Your skills in copywriting and content production will be key in this role.
      </p>
      <h3>Key Responsibilities:</h3>
      <ul>
        <li>Develop and manage social media campaigns.</li>
        <li>Create engaging content across various platforms.</li>
        <li>Analyze and report on social media performance metrics.</li>
      </ul>
      <h3>Required Strengths:</h3>
      <ul>
        <li>Strong copywriting skills.</li>
        <li>Experience in social media management.</li>
        <li>Ability to produce high-quality content.</li>
      </ul>
    </section>

    <section class="job-listing">
      <h2>Technical Sales</h2>
      <p>
        We are seeking a Technical Sales expert to drive customer acquisition efforts. You will work closely with clients to understand their needs and present our solutions effectively.
      </p>
      <h3>Key Responsibilities:</h3>
      <ul>
        <li>Identify and engage potential customers.</li>
        <li>Present technical solutions to clients.</li>
        <li>Manage customer relationships to drive sales growth.</li>
      </ul>
      <h3>Required Strengths:</h3>
      <ul>
        <li>Strong customer acquisition skills.</li>
        <li>Technical knowledge of our products and services.</li>
        <li>Excellent communication and presentation abilities.</li>
      </ul>
    </section>

    <section class="job-listing">
      <h2>Platform Developer</h2>
      <p>
        As a Platform Developer, you will focus on our infrastructure and documentation. Your expertise in DevOps and VueJS will help us create a robust and user-friendly platform.
      </p>
      <h3>Key Responsibilities:</h3>
      <ul>
        <li>Develop and maintain platform features.</li>
        <li>Create and manage documentation for internal and external use.</li>
        <li>Ensure smooth deployment and integration processes.</li>
      </ul>
      <h3>Required Strengths:</h3>
      <ul>
        <li>Strong DevOps skills.</li>
        <li>Proficient in VueJS.</li>
        <li>Ability to write clear and comprehensive documentation.</li>
      </ul>
    </section>

    <section class="job-listing">
      <h2>Frontend Developer</h2>
      <p>
        We are looking for a creative Frontend Developer to enhance our user interface and design aesthetics. Your skills in VueJS, along with a keen eye for design, will be vital for this role.
      </p>
      <h3>Key Responsibilities:</h3>
      <ul>
        <li>Develop visually appealing user interfaces using VueJS.</li>
        <li>Collaborate with designers to bring concepts to life.</li>
        <li>Ensure responsiveness and performance of web applications.</li>
      </ul>
      <h3>Required Strengths:</h3>
      <ul>
        <li>Proficient in VueJS.</li>
        <li>Experience with Adobe Suite or Figma.</li>
        <li>Aesthetic sensibility with a background in art, design, or related fields.</li>
      </ul>
    </section>

  </div>
</template>

<script>
export default {
  name: "Careers",
  mounted() {
    // Scroll to the top of the page over 1 second if scrolled down
    if (window.scrollY > 0) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // Smooth scrolling effect
      });
    }
  },
};
</script>

<style scoped>
.careers {
  padding: 20px;
  font-family: Arial, sans-serif;
}

h1 {
  text-align: center;
  color: #2c3e50;
}

h2 {
  margin-top: 30px;
  color: #34495e;
}

h3 {
  margin-top: 20px;
  color: #7f8c8d;
}

p {
  line-height: 1.6;
  margin-bottom: 20px;
}

ul {
  list-style-type: disc;
  margin-left: 20px;
  margin-bottom: 20px;
}

.job-listing {
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
</style>
