<template>
    <div class="faq-container bg-white min-h-screen">
      <!-- McKinsey Section -->
      <div class="max-w-4xl mx-auto p-6">
        <h2 class="text-2xl font-bold text-blue-800 mb-6">Geschäftsführer Fragen</h2>
        <div class="space-y-4">
          <div v-for="(item, index) in ceoQuestions" 
               :key="'mc-' + index" 
               class="border rounded-lg shadow-sm">
            <button 
              @click="toggleMcKinsey(index)"
              class="w-full px-6 py-4 text-left flex justify-between items-center hover:bg-blue-50 transition-colors"
              :class="{ 'bg-blue-50': openMcKinsey === index }"
            >
              <span class="font-medium text-blue-900">{{ item.question }}</span>
              <span class="text-blue-500">
                {{ openMcKinsey === index ? '−' : '+' }}
              </span>
            </button>
            <div 
              v-show="openMcKinsey === index"
              class="px-6 py-4 bg-gray-50 border-t"
            >
              <p class="text-gray-700">{{ item.answer }}</p>
            </div>
          </div>
        </div>
  
        <h2 class="text-2xl font-bold text-blue-800 mt-12 mb-6">Senior Developer Fragen</h2>
        <div class="space-y-4">
          <div v-for="(item, index) in devQuestions" 
               :key="'dev-' + index" 
               class="border rounded-lg shadow-sm">
            <button 
              @click="toggleDev(index)"
              class="w-full px-6 py-4 text-left flex justify-between items-center hover:bg-blue-50 transition-colors"
              :class="{ 'bg-blue-50': openDev === index }"
            >
              <span class="font-medium text-blue-900">{{ item.question }}</span>
              <span class="text-blue-500">
                {{ openDev === index ? '−' : '+' }}
              </span>
            </button>
            <div 
              v-show="openDev === index"
              class="px-6 py-4 bg-gray-50 border-t"
            >
              <p class="text-gray-700">{{ item.answer }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'SecurityFAQ',
    data() {
      return {
        openMcKinsey: null,
        openDev: null,
        ceoQuestions: [
          {
            question: "Wie wird die Preisgestaltung ermittelt, und wie korreliert sie mit dem erwarteten ROI für verschiedene Unternehmensgrößen?",
            answer: "Die Preisgestaltung basiert auf einer detaillierten Analyse der Implementierungskosten, des Marktwertes und des erwarteten ROI. Der ROI wird individuell für verschiedene Unternehmensgrößen berechnet und berücksichtigt Faktoren wie potenzielle Schadensvermeidung und Effizienzsteigerungen."
          },
          {
            question: "Welcher Support ist im Cyberdefense Excellence Programm inbegriffen?",
            answer: "Nach Implementierung der neuen Sicherheitsmaßnahmen erhalten Sie ein Locality Security Gold Zertifikat. Dieses gilt 3 Jahre lang und kommt mit einem jährlichen Code-Audit und Penetrationstest. "
          },
          {
            question: "Welche konkreten Ressourcen (Personal, Zeit, Budget) müssen wir als Unternehmen bereitstellen?",
            answer: "Neben dem Programmbudget wird ein interner Projektleiter (ca. 25% Auslastung während der Implementierung) benötigt. Fachabteilungen werden punktuell eingebunden. Ein detaillierter Ressourcenplan wird vor Projektstart erstellt."
          },
          {
            question: "Benötige ich ein Software-Entwickler Team, um geschützt zu sein?",
            answer: "Für das Cyberdefense Excellence Program benötigen Sie kein inhouse-Team von Entwicklern. Wenn Sie den Cybersecurity-Audit in Anspruch nehmen, schulen wir Ihre Entwickler, um die empfohlenen Sicherheitsmaßnahmen nachhaltig korrekt umzusetzen. Falls Sie nicht über ein eigenes Entwickler-Team verfügen, sollten Sie das Cyberdefense Excellence Program nehmen."
          },
          {
            question: "Wie können Sicherheitslücken in bestehender geschäftskritischer Software addressiert werden, deren Source-Code wir nicht kontrollieren?",
            answer: "Falls sie unsichere Legacy-Tools verwenden, können wir diese auch im Zuge unserer 'Business Unification Suite' für Sie neu entwickeln. Dies bietet sich insbesondere nach einem erfolgreichen Penetration Test gegen diese Software an."
          },
          {
            question: "Welche quantifizierbaren KPIs und Erfolgsmetriken werden verwendet, um den Erfolg des Programms zu messen?",
            answer: "Die KPIs umfassen Incident Response Zeiten, Security Scores, Compliance-Metriken, Mitarbeiter-Awareness-Levels und System-Verfügbarkeitsraten."
          },
          {
            question: "Welche messbaren Verbesserungen in unserer Cyber-Versicherungssituation können wir erwarten?",
            answer: "Die Implementierung führt typischerweise zu reduzierten Versicherungsprämien und verbesserten Konditionen. Konkrete Einsparungen werden in Abstimmung mit Ihrem Versicherer ermittelt. Durchschnittlich sind Prämieneinsparungen von 15-30% realistisch."
          },
          {
            question: "Wie differenziert sich dieses Excellence Program von Wettbewerbsangeboten im gleichen Preissegment?",
            answer: "Das Programm bietet eine einzigartige Kombination aus technischer Implementierung, Mitarbeiterschulung und kontinuierlicher Überwachung mit speziellem Fokus auf Geschäftskontinuität und Datenlecksprävention."
          },
          {
            question: "Welche spezifischen Branchen-Compliance-Anforderungen über die DSGVO hinaus werden berücksichtigt?",
            answer: "Das Programm berücksichtigt branchenspezifische Standards wie ISO 27001, PCI DSS, HIPAA und weitere relevante Compliance-Anforderungen."
          },
          {
            question: "Was ist ein Locality Security Silber Zertifikat?",
            answer: "Sie erhalten ein Silber Zertifikat, wenn wir uns durch einen Penetrationstest keinen Zugang zu ihren Systemen verschaffen konnten. Es bedeutet, dass Ihr Unternehmen unmittelbar gegen Cyberangriffe geschützt ist."
          },
          {
            question: "Was ist ein Locality Security Gold Zertifikat?",
            answer: "Sie erhalten ein Gold Zertifikat, wenn Sie Teil des Cyberdefense Excellence Programmes sind. Es bedeutet, Ihr Unternehmen ist sowohl gegen Cyberangriffe, als auch gegen Datenlecks und kritischen Datenverlust geschützt. Ein Locality Security Gold Zertifikat wird auf 3 Jahre verliehen."
          },
          {
            question: "Wie skalierbar ist das Programm bei schnellem Unternehmenswachstum oder internationaler Expansion?",
            answer: "Das Programm ist modular aufgebaut und kann flexibel an wachsende Unternehmensanforderungen angepasst werden, einschließlich internationaler Compliance-Standards."
          },
          {
            question: "Welche Versicherungsaspekte und Haftungsbegrenzungen sind im Programm enthalten?",
            answer: "Das Programm beinhaltet eine umfassende Cyber-Versicherung und klar definierte Haftungsvereinbarungen für verschiedene Sicherheitsvorfälle."
          },
          {
            question: "Wie wird der Business Impact während der 12-wöchigen Implementierungsphase minimiert?",
            answer: "Die Implementierung erfolgt in Phasen mit minimalen Systemunterbrechungen und außerhalb der Kerngeschäftszeiten."
          },
          {
            question: "Welche wiederkehrenden Kosten entstehen nach der initialen Implementierung?",
            answer: "Die wiederkehrenden Kosten umfassen Monitoring, Updates, Support und regelmäßige Sicherheitsaudits."
          },
          {
            question: "Wie wird der ROI der Mitarbeiterschulung gemessen und nachhaltig gesichert?",
            answer: "Der ROI wird durch regelmäßige Assessments, Phishing-Tests und Sicherheitsmetriken gemessen."
          },
          {
            question: "Welche persönliche Haftung kann ich als Geschäftsführer im Falle eines Cybervorfalls ausschließen, wenn wir dieses Programm implementieren?",
            answer: "Das Programm bietet dokumentierte Nachweise für die Erfüllung der Sorgfaltspflicht und reduziert damit die persönliche Haftung erheblich. Es umfasst rechtskonforme Prozesse, Dokumentation und Zertifizierungen, die im Schadensfall die gebotene Sorgfalt belegen."
          },
          {
            question: "Wie flexibel ist das Programm bei zukünftigen Änderungen unserer Geschäftsstrategie (z.B. neue Märkte, Akquisitionen, neue Technologien)?",
            answer: "Das Programm ist modular aufgebaut und skalierbar. Es kann an neue Geschäftsanforderungen, Märkte und Technologien angepasst werden. Updates und Erweiterungen sind Teil des kontinuierlichen Verbesserungsprozesses."
          },
          {
            question: "Wie wird die Effektivität des Programms langfristig sichergestellt und wie werden Anpassungen an neue Bedrohungen vorgenommen?",
            answer: "Kontinuierliches Monitoring, regelmäßige Audits und automatische Updates gewährleisten die langfristige Effektivität. Vierteljährliche Reports zeigen den Sicherheitsstatus und notwendige Anpassungen werden proaktiv vorgeschlagen. Zusätzlich erhalten Sie unseren Locality High Security Newsletter."
          },
          {
            question: "Welche Exit-Strategie oder Vendor-Lock-in-Vermeidung ist vorgesehen?",
            answer: "Das Programm verwendet Standardtechnologien und bietet dokumentierte Übergabeprozesse für einen möglichen Anbieterwechsel."
          },
          {
            question: "Was sind die konkreten Business Risks, die durch dieses Programm adressiert werden und wie wird der Return on Security Investment (ROSI) berechnet?",
            answer: "Das Programm adressiert primär Risiken wie Datenverlust, Betriebsunterbrechungen, Informationsgewinn durch Wettbewerber, Bußgelder für Datenlecks und Reputationsschäden. Der ROSI wird anhand der Schadenvermeidung, Effizienzsteigerungen und reduzierter Versicherungsprämien berechnet. Eine detaillierte Risikomatrix und ROSI-Kalkulation wird im Erstgespräch vorgestellt."
          },

        ],
        devQuestions: [
          {
            question: "Wie detailliert ist die Code-Review-Methodik, und werden dabei auch Container-Security und CI/CD-Pipeline-Sicherheit berücksichtigt?",
            answer: "Die Code-Review-Methodik umfasst automatisierte und manuelle Prüfungen, SAST/DAST-Tools und spezielle Container-Security-Scans in der CI/CD-Pipeline."
          },
          {
            question: "Welche spezifischen Tools und Technologien werden für das kontinuierliche Security-Monitoring eingesetzt?",
            answer: "Zum Einsatz kommen Enterprise-SIEM-Lösungen, IDS/IPS-Systeme und spezialisierte Threat-Intelligence-Plattformen."
          },
          {
            question: "Wie wird die Integration mit bestehenden Legacy-Systemen und deren spezifische Sicherheitsrisiken adressiert?",
            answer: "Legacy-Systeme werden durch zusätzliche Sicherheitsschichten und spezielle Monitoring-Tools abgesichert."
          },
          {
            question: "Welche Backup-Strategie wird implementiert (RPO/RTO), und wie wird die Disaster Recovery getestet?",
            answer: "Implementation einer 3-2-1 Backup-Strategie mit definierten RPO/RTO-Zielen und regelmäßigen DR-Tests."
          },
          {
            question: "Wie wird die Sicherheit von Microservices-Architekturen und API-Kommunikation gewährleistet?",
            answer: "Implementierung von Service Mesh, API-Gateway-Security und Zero-Trust-Architektur für Microservices."
          },
          {
            question: "Welche Automatisierungsgrade werden bei der Sicherheitsüberwachung erreicht?",
            answer: "Hoher Automatisierungsgrad durch SOAR-Plattformen und automatisierte Incident Response Workflows."
          },
          {
            question: "Wie wird die Sicherheit von Cloud-native Anwendungen und hybrid Cloud-Umgebungen gewährleistet?",
            answer: "Implementierung von Cloud Security Posture Management (CSPM) und Cloud Workload Protection Platforms (CWPP)."
          },
          {
            question: "Welche konkreten Maßnahmen werden zur Absicherung der Development-Umgebungen getroffen?",
            answer: "Sichere Development-Umgebungen durch IAM, Secrets Management und geschützte Build-Pipelines."
          },
          {
            question: "Wie wird das Security-Logging implementiert und wie werden Security-Events korreliert?",
            answer: "Zentralisiertes Logging mit ELK-Stack oder vergleichbaren Systemen, sowie ML-basierte Event-Korrelation."
          },
          {
            question: "Welche Strategie wird für das Management von Security-Updates und Patches verfolgt?",
            answer: "Automatisiertes Patch-Management mit Risk-Based Patching und definierten Maintenance-Windows."
          }
        ]
      }
    },
    methods: {
      toggleMcKinsey(index) {
        this.openMcKinsey = this.openMcKinsey === index ? null : index;
        this.openDev = null; // Close any open dev questions
      },
      toggleDev(index) {
        this.openDev = this.openDev === index ? null : index;
        this.openMcKinsey = null; // Close any open McKinsey questions
      }
    }
  }
  </script>
  
  <style scoped>
  .faq-container {
    background: linear-gradient(to bottom, #f8fafc, #ffffff);
  }
  </style>