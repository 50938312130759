<template>
  <div class="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8" style="background-color: transparent; border-radius: 25px;">
    <div v-if="isLoggedIn" class="sm:mx-auto sm:w-full sm:max-w-sm text-center">
      <v-icon color="#ec38bc" large>mdi-check-circle</v-icon>
      <div class="mt-4 text-2xl font-bold logged-in-message">You are logged in!!</div>
    </div>

    <div v-else>
      <div class="sm:mx-auto sm:w-full sm:max-w-sm">

        <h2
          class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900"
          style="color: #fdeff9; font-weight: 300;"
        >
          {{ isLogin ? 'Sign in to your account' : 'Register a new account' }}
        </h2>
      </div>

      <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form class="space-y-6" @submit.prevent="isLogin ? submitLogin() : submitRegister()">
          <div v-if="!isLogin">
            <label
              for="companyName"
              class="block text-sm font-medium leading-6 text-gray-900"
              style="color: #fdeff9; font-weight: 300;"
              >Company Name</label
            >
            <div class="mt-2">
              <input
                id="companyName"
                v-model="registerCompanyName"
                name="companyName"
                type="text"
                required
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                style="background-color: #fdeff9;"
              />
            </div>
          </div>

          <div>
            <label
              for="email"
              class="block text-sm font-medium leading-6 text-gray-900"
              style="color: #fdeff9; font-weight: 300;"
              >Email address</label
            >
            <div class="mt-2">
              <input
                id="email"
                v-model="email"
                name="email"
                type="email"
                autocomplete="email"
                required
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                style="background-color: #fdeff9;"
              />
            </div>
          </div>

          <div>
            <div class="flex items-center justify-between">
              <label
                for="password"
                class="block text-sm font-medium leading-6 text-gray-900"
                style="color: #fdeff9; font-weight: 300;"
                >Password</label
              >
              <div class="text-sm" v-if="isLogin">
                <a
                  href="#"
                  class="font-semibold text-indigo-600 hover:text-indigo-500"
                  style="color: #ec38bc; font-weight: 300;"
                  >Forgot password?</a
                >
              </div>
            </div>
            <div class="mt-2">
              <input
                id="password"
                v-model="password"
                name="password"
                type="password"
                autocomplete="current-password"
                required
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                style="background-color: #fdeff9;"
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              class="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              style="background-color: #7303c0;"
            >
              {{ isLogin ? 'Sign in' : 'Register' }}
            </button>
            <div v-if="loginError" class="error">{{ loginError }}</div>
            <div v-if="registerError" class="error">{{ registerError }}</div>
          </div>
        </form>

        <p class="mt-10 text-center text-sm text-gray-500" style="color: #fdeff9;">
          {{ isLogin ? 'Not a member?' : 'Already a member?' }}
          <a
            href="#"
            class="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
            @click.prevent="toggleForm"
            style="color: #ec38bc; font-weight: 300;"
            >{{ isLogin ? 'Create a new account' : 'Sign in to your account' }}</a
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

export default {
  name: 'LoginRegister',
  data() {
    return {
      isLogin: true,
    };
  },
  computed: {
    ...mapState({
      login: state => state.login,
      register: state => state.register,
      loginError: state => state.loginError,
      registerError: state => state.registerError
    }),
    ...mapGetters(['isLoggedIn']),
    email: {
      get() {
        return this.isLogin ? this.login.email : this.register.email;
      },
      set(value) {
        if (this.isLogin) {
          this.setLoginEmail(value);
        } else {
          this.setRegisterEmail(value);
        }
      }
    },
    password: {
      get() {
        return this.isLogin ? this.login.password : this.register.password;
      },
      set(value) {
        if (this.isLogin) {
          this.setLoginPassword(value);
        } else {
          this.setRegisterPassword(value);
        }
      }
    },
    registerCompanyName: {
      get() {
        return this.register.companyName;
      },
      set(value) {
        this.setRegisterCompanyName(value);
      }
    }
  },
  methods: {
    ...mapMutations({
      setLoginEmail: 'SET_LOGIN_EMAIL',
      setLoginPassword: 'SET_LOGIN_PASSWORD',
      setRegisterCompanyName: 'SET_REGISTER_COMPANY_NAME',
      setRegisterEmail: 'SET_REGISTER_EMAIL',
      setRegisterPassword: 'SET_REGISTER_PASSWORD'
    }),
    ...mapActions(['submitLogin', 'submitRegister']),
    toggleForm() {
      this.isLogin = !this.isLogin;
    }
  }
};
</script>

<style scoped>
.error {
  color: red;
  margin-top: 10px;
}
.logged-in-message {
  color: #ec38bc;
  font-weight: 300;
}
</style>
