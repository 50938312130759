<template>
  <div class="flex items-center justify-center h-screen w-screen bg-black text-white">
    <div class="p-5 text-left">
      <h1 class="text-4xl font-normal">
        One! 
        <span class="text-purple-500">
          Just one!<br>
        </span>
      </h1>
      <h2 class="text-2xl font-normal">
        with a little help from the
        <span class="text-purple-500">
          Locality Cloud<br>
        </span>
      </h2>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PresentationSlide0',
}
</script>
