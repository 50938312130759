<template>
    <div>
      <Header :activeTab="'setup'" :isOpaque="true" />
      <Header :activeTab="'setup'" :isOpaque="true" />
      <Setup />
      <Footer />
    </div>
  </template>
  
  <script>
  import Header from '@/components/HeaderComponent.vue';
  import Setup from '@/views/Setup.vue';
  import Footer from '../components/Footer.vue';
  
  export default {
    name: 'SetupPage',
    components: {
      Header,
      Setup,
      Footer
    }
  };
  </script>
  
  <style>
  .DOCU {
    color:red;
  }
  /* You can add some styles here if needed */
  </style>
  