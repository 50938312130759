<template>
  <div class="relative">
    <v-btn
          color="yellow"
          v-bind="props"
          class="login-btn"
           @click="showLoginDialog" v-if="!isLoggedIn"
        >
        Login
    </v-btn>

    <v-menu  v-if="isLoggedIn">
      <template v-slot:activator="{ props }">
        <v-btn
          color="yellow"
          v-bind="props"
          class="login-btn"
        >
        You are logged in
        </v-btn>
      </template>
      <v-list>

        <v-list-item @click="handleLogout">
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item>
        <v-list-item @click="handleLogout">
          <v-list-item-title>My Apps</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-dialog v-model="loginDialog" max-width="700px">
      <v-card style="height: 620px; border-radius: 52px; padding: 5px; background-color: #02000e;">
        <LoginRegister @close="closeLoginDialog" />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import LoginRegister from './LoginRegister.vue';

export default {
  name: 'LoginButton',
  components: {
    LoginRegister
  },
  data() {
    return {
      dropdown: false,
      loginDialog: false,
      dropdownPosition: { x: 0, y: 0 },
    };
  },
  computed: {
    ...mapGetters(['isLoggedIn'])
  },
  methods: {
    ...mapActions(['logout']),
    showLoginDialog() {
      this.loginDialog = true;
    },
    closeLoginDialog() {
      this.loginDialog = false;
    },
    toggleDropdown(event) {
      this.dropdownPosition.x = event.clientX;
      this.dropdownPosition.y = event.clientY;
      this.dropdown = !this.dropdown;
    },
    handleLogout() {
      this.logout();
      this.dropdown = false;
    }
  }
};
</script>

<style scoped>
.login-btn {
  background-color: black;
  color: white;
  font-size: 14px;
  width: 136px;
}
.v-menu__content {
  z-index: 1000 !important;
  position: absolute !important;
  right: 0 !important;
}
.relative {
  margin-right: 20px;
  margin-left: 20px;
}
</style>
