<template>
  <ServiceLayout>
    <div class="services-list">
      <header class="hero-section">
        <h1 class="title">Our Services</h1>
        <p class="subtitle">Explore our comprehensive range of services designed to protect and enhance your business.</p>
      </header>

      <div class="service-items">
        <router-link to="/services/cybersecurity" class="service-card">
          <h2 class="service-title">Cybersecurity & Compliance Audit</h2>
          <p class="service-description">Enhance your security posture and ensure compliance with EU regulations.</p>
        </router-link>

        <router-link to="/services/business-suite" class="service-card">
          <h2 class="service-title">Business Unification Suite</h2>
          <p class="service-description">Streamline your business processes and enhance collaboration.</p>
        </router-link>
      </div>
    </div>
  </ServiceLayout>
</template>

<script>
import ServiceLayout from '@/layouts/ServiceLayout.vue';

export default {
  components: {
    ServiceLayout,
  },
};
</script>

<style scoped>
.services-list {
  padding: 32px; /* p-8 */
  color: #D1D5DB; /* Body Text Color */
  background-color: #1a1a1a; /* Dark Background */
}

.hero-section {
  margin-bottom: 32px; /* mb-8 */
  text-align: center;
}

.title {
  font-size: 2.25rem; /* 4xl */
  color: #FFFFFF; /* Headings Color */
}

.subtitle {
  font-size: 1rem; /* md */
  line-height: 1.5;
  color: #93C5FD; /* Highlight Color */
}

.service-items {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Responsive grid */
  gap: 32px; /* gap-8 */
  margin-bottom: 48px; /* mb-12 */
}

.service-card {
  background-color: #374151; /* Card Background */
  border-radius: 0.75rem; /* rounded-xl */
  padding: 24px; /* px-6 py-4 */
  text-align: center;
  text-decoration: none;
  color: #D1D5DB; /* Body Text Color */
  transition: all 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* shadow-lg */
}

.service-card:hover {
  background: linear-gradient(to bottom right, #1E3A8A, #1D4ED8); /* Highlighted Gradient */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* hover:shadow-xl */
}

.service-title {
  font-size: 1.5rem; /* xl */
  margin: 0 0 8px; /* mb-2 */
}

.service-description {
  margin: 0;
  font-size: 1rem; /* md */
}
</style>
