<template>
  <div class="landing-page relative leading-normal tracking-normal text-indigo-400 bg-cover bg-fixed" style="background-image: url('/header.png');">
    <!-- Main Content -->
    <div class="relative container mx-auto px-6 flex flex-wrap flex-col lg:flex-row items-center h-full justify-center">
      <!-- Left Column -->
      <div class="maincontent flex flex-col w-full lg:w-2/5 justify-center lg:items-start overflow-y-hidden">
        <h1 class="my-4 text-3xl md:text-5xl text-white opacity-75 font-bold leading-tight text-left">
          Fueling the development of
          <span class="bg-clip-text text-transparent bg-gradient-to-r from-green-400 via-pink-500 to-purple-500">
            Social Apps
          </span>
          for a new world!
        </h1>
        <p class="leading-normal text-base md:text-2xl mb-8 text-left">
          Build serverless end-to-end-encrypted social apps with Flutter!
        </p>
        <a href="/setup" class="devbutton inline-block px-6 py-3 bg-indigo-500 text-white font-semibold text-xl rounded hover:bg-indigo-600">
          Start Developing
        </a>
      </div>
      <!-- Right Column -->
      <div class="w-full lg:w-3/5 p-12 overflow-hidden">
        <img :src="require('@/assets/solvent2.webp')" alt="Image" class="imagex mx-auto w-full md:w-4/5 transform -rotate-6 transition hover:scale-105 duration-700 ease-in-out hover:rotate-6" />
      </div>
    </div>
  </div>
</template>

<script>
import BarChart from './BarChart.vue'

export default {
  name: 'LandingPage',
  components: {
    BarChart
  }
}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");

html, body {
  height: 100%; /* Ensure body and html take up full height */
  margin: 0; /* Remove default margins */
  overflow: hidden; /* Prevent scrolling */
}

.landing-page {
  min-height: 120vh; /* Ensure the landing page takes full height */
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Prevent scrollbars */
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.maincontent {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content vertically */
  flex-grow: 1;
  text-align: center; /* Ensure centered text alignment on all screens */
  padding: 24px 0; /* Adjust padding */
}

.devbutton {
  max-width: 280px;
}

.imagex {
  max-height: 470px;
  object-fit: contain;
  margin-bottom: 50px;
}

/* Media Query for very small screens (640px and below) */
@media (max-width: 640px) {
  .maincontent {
    justify-content: center; /* Center items vertically */
    padding: 0; /* Remove padding for full centering */
  }

  .imagex {
    display: none; /* Hide image on small screens */
  }
}

</style>