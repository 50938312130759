<template>
    <ServiceLayout
      title="Enterprise Cybersecurity Training"
      subtitle="Umfassendes Schulungspaket für moderne Unternehmenssicherheit und Bedrohungsabwehr"
    >
      <!-- Hero Section -->
      <div class="bg-gradient-to-br from-gray-900 via-blue-900 to-purple-900 p-8 rounded-xl mb-8 relative overflow-hidden">
        <div class="absolute inset-0 opacity-10">
          <div class="animate-pulse absolute inset-0 bg-gradient-to-r from-blue-500 to-purple-500"></div>
        </div>
        <div class="relative z-10">
          <h1 class="text-4xl font-bold text-white mb-4">
            Cyber Defense Excellence Program
          </h1>
          <p class="text-xl text-blue-200 mb-6">
            Schützen Sie Ihr Unternehmen mit modernsten Sicherheitspraktiken
          </p>
          <button class="bg-blue-500 hover:bg-blue-400 text-white px-8 py-3 rounded-lg font-semibold transition-all shadow-lg hover:shadow-blue-500/25">
            Sicherheitstraining starten
          </button>
        </div>
      </div>
  
      <!-- Course Modules Grid -->
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-12">
        <!-- Core Modules Card -->
        <div class="bg-gray-800 rounded-xl shadow-lg p-6 border border-gray-700 hover:shadow-xl hover:shadow-blue-500/10 transition-all">
          <div class="flex items-center mb-4">
            <div class="w-12 h-12 bg-blue-900 rounded-full flex items-center justify-center">
              <i class="fas fa-shield-alt text-blue-400 text-xl"></i>
            </div>
            <h2 class="text-xl font-bold text-white ml-4">Kursmodule</h2>
          </div>
          <ul class="space-y-3">
            <li v-for="module in courseModules" :key="module.title" class="flex items-start">
              <span class="text-green-400 mr-2">✓</span>
              <div>
                <span class="text-gray-300 font-semibold">{{ module.title }}</span>
                <p class="text-gray-400 text-sm">{{ module.duration }}</p>
              </div>
            </li>
          </ul>
        </div>
  
        <!-- Certification Path -->
        <div class="bg-gray-800 rounded-xl shadow-lg p-6 border border-gray-700 hover:shadow-xl hover:shadow-blue-500/10 transition-all">
          <div class="flex items-center mb-4">
            <div class="w-12 h-12 bg-purple-900 rounded-full flex items-center justify-center">
              <i class="fas fa-certificate text-purple-400 text-xl"></i>
            </div>
            <h2 class="text-xl font-bold text-white ml-4">Zertifizierungsweg</h2>
          </div>
          <div class="space-y-4">
            <div v-for="(cert, index) in certificationPath" :key="index" class="relative pl-8 border-l-2 border-gray-600 pb-4 last:pb-0">
              <div class="absolute -left-[5px] top-0 w-2 h-2 rounded-full bg-blue-400"></div>
              <h3 class="font-semibold text-blue-300">{{ cert.level }}</h3>
              <p class="text-gray-400">{{ cert.description }}</p>
            </div>
          </div>
        </div>
  
        <!-- Investment Card -->
        <div class="bg-gradient-to-br from-blue-900 to-purple-900 rounded-xl shadow-lg p-6 text-white">
          <div class="flex items-center mb-4">
            <div class="w-12 h-12 bg-blue-700 rounded-full flex items-center justify-center">
              <i class="fas fa-euro-sign text-white text-xl"></i>
            </div>
            <h2 class="text-xl font-bold ml-4">Investment</h2>
          </div>
          <div class="text-3xl font-bold mb-4">€2.5K - €5K</div>
          <p class="mb-4 text-blue-200">Pro Teilnehmer, Gruppenrabatte verfügbar</p>
          <ul class="space-y-2">
            <li v-for="option in pricingOptions" :key="option" class="flex items-center text-blue-200">
              <span class="mr-2">•</span>
              <span>{{ option }}</span>
            </li>
          </ul>
          <button class="mt-6 w-full bg-blue-500 text-white py-3 rounded-lg font-semibold hover:bg-blue-400 transition-all">
            Kursangebot anfordern
          </button>
        </div>
      </div>
  
      <!-- Course Benefits -->
      <div class="bg-gray-800 rounded-xl shadow-lg p-8 border border-gray-700 mb-8">
        <h2 class="text-2xl font-bold text-white mb-6">
          Ihre Vorteile
        </h2>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div v-for="benefit in benefits" :key="benefit.title" class="flex items-start space-x-4">
            <div class="w-8 h-8 bg-blue-900 rounded-full flex items-center justify-center flex-shrink-0">
              <i :class="benefit.icon + ' text-blue-400'"></i>
            </div>
            <div>
              <h3 class="text-lg font-semibold text-white">{{ benefit.title }}</h3>
              <p class="text-gray-400">{{ benefit.description }}</p>
            </div>
          </div>
        </div>
      </div>
  
      <!-- Detailed Description -->
      <div class="bg-gray-800 rounded-xl shadow-lg p-8 border border-gray-700">
        <h2 class="text-2xl font-bold text-white mb-6">
          Warum unser Cybersecurity Training?
        </h2>
        <div class="space-y-4 text-gray-300">
          <p v-for="(paragraph, index) in description" :key="index">
            {{ paragraph }}
          </p>
        </div>
        <div class="mt-8 flex justify-center">
          <button class="bg-blue-500 hover:bg-blue-400 text-white px-8 py-3 rounded-lg font-semibold transition-all shadow-lg hover:shadow-blue-500/25">
            Jetzt anmelden
          </button>
        </div>
      </div>
    </ServiceLayout>
  </template>
  
  <script>
  import ServiceLayout from '@/components/ServiceLayout.vue'
  
  export default {
    name: 'CybersecurityCourse',
    components: {
      ServiceLayout
    },
    data() {
      return {
        courseModules: [
          { title: 'Grundlagen der Cybersicherheit', duration: '2 Wochen' },
          { title: 'Bedrohungserkennung & -abwehr', duration: '3 Wochen' },
          { title: 'Incident Response', duration: '2 Wochen' },
          { title: 'Sicherheitsarchitektur', duration: '3 Wochen' },
          { title: 'Penetration Testing', duration: '2 Wochen' }
        ],
        certificationPath: [
          { 
            level: 'Basic Security Analyst',
            description: 'Grundlegende Sicherheitskonzepte und -praktiken'
          },
          {
            level: 'Advanced Security Professional',
            description: 'Fortgeschrittene Bedrohungsanalyse und Abwehrstrategien'
          },
          {
            level: 'Security Expert',
            description: 'Komplexe Sicherheitsarchitektur und Incident Response'
          }
        ],
        pricingOptions: [
          'Flexible Zahlungspläne',
          'Unternehmensrabatte',
          'Inklusive Zertifizierung',
          'Lifetime Zugang zu Updates'
        ],
        benefits: [
          {
            title: 'Praxisorientiert',
            description: 'Hands-on Labs und realistische Szenarien',
            icon: 'fas fa-laptop-code'
          },
          {
            title: 'Experten-Mentoring',
            description: 'Direkter Zugang zu Sicherheitsexperten',
            icon: 'fas fa-user-shield'
          },
          {
            title: 'Flexibles Lernen',
            description: 'Online und Offline Kursmaterialien',
            icon: 'fas fa-clock'
          },
          {
            title: 'Industriestandards',
            description: 'Alignierung mit aktuellen Compliance-Anforderungen',
            icon: 'fas fa-check-circle'
          }
        ],
        description: [
          'In einer Zeit, in der Cyberbedrohungen immer komplexer werden, ist fundiertes Sicherheitswissen unerlässlich. Unser Cybersecurity Training wurde von Experten entwickelt, um Ihre Mitarbeiter auf die aktuellen Herausforderungen vorzubereiten.',
          'Das Programm kombiniert theoretisches Wissen mit praktischen Übungen in einer sicheren Laborumgebung. Teilnehmer lernen nicht nur Bedrohungen zu erkennen, sondern auch effektiv darauf zu reagieren.',
          'Mit unserem zertifizierten Training investieren Sie in die Sicherheit Ihres Unternehmens und die Kompetenz Ihrer Mitarbeiter. Die Kurse werden regelmäßig aktualisiert, um neue Bedrohungen und Abwehrstrategien zu berücksichtigen.',
          'Nach Abschluss des Programms sind Ihre Mitarbeiter in der Lage, Sicherheitsrisiken frühzeitig zu erkennen und professionell zu bewältigen.'
        ]
      }
    }
  }
  </script>
  
  <style scoped>
  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.3s ease;
  }
  .fade-enter-from, .fade-leave-to {
    opacity: 0;
  }
  </style>