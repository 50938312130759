<template>
  <div class="scrollable-container">
    <Header activeTab="locality" isOpaque="true" />
    <Header activeTab="locality" isOpaque="true" />
    
    <main>
      <LandingPage />

      <section id="presentation0">
        <PresentationSlide0 />
      </section>
      <section id="presentation1">
        <PresentationSlide1 />
      </section>
      <section id="presentation5">
        <PresentationSlide5 />
      </section>

      <section id="presentation2">
        <PresentationSlide2 />
      </section>
      
      <section id="presentation2">
        <PresentationSlide3 />
      </section>
      <section id="presentation4">
        <PresentationSlide4 />
      </section>

      <section id="setup">
        <Setup />
      </section>

      

      <section id="documentation">
        <Documentation />
      </section>
    </main>

    <footer>
      <Footer />
    </footer>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

// Components
import Header from '../components/HeaderComponent.vue';
import LandingPage from '../components/LandingPage.vue';
import Setup from '@/views/Setup.vue';
import Documentation from '@/views/Documentation.vue';
import Footer from '../components/Footer.vue';

import PresentationSlide0 from '@/components/presentation_slides/PresentationSlide0.vue';
import PresentationSlide1 from '@/components/presentation_slides/PresentationSlide1.vue';
import PresentationSlide2 from '@/components/presentation_slides/PresentationSlide2.vue';
import PresentationSlide3 from '@/components/presentation_slides/PresentationSlide3.vue';
import PresentationSlide4 from '@/components/presentation_slides/PresentationSlide4.vue';
import PresentationSlide5 from '@/components/presentation_slides/PresentationSlide5.vue';

export default defineComponent({
  name: 'HomeView',

  components: {
    Header,
    LandingPage,
    Setup,
    Documentation,
    Footer,
    PresentationSlide0,  
    PresentationSlide1,
    PresentationSlide2,
    PresentationSlide3,
    PresentationSlide4,
    PresentationSlide5
  },
});
</script>

<style scoped>
.scrollable-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  flex-grow: 1;
  width: 100%;
}

section {
  min-height: 100vh;
  width: 100%;
}

footer {
  width: 100%;
  margin-top: auto;
}
</style>