<template>
    <div>
      <Header :isOpaque="true" /> 
      <div style="background-color: #00000079; width: 100%; height: 70px; position: fixed;"></div>
      <Careers />
      <Footer fillColor="#ffffff" />
    </div>
  </template>
  
  <script>
  import Header from '@/components/HeaderComponent.vue';
  import Careers from '@/views/Careers.vue';
  import Footer from '../components/Footer.vue';
  
  export default {
    name: 'CareersPage',
    components: {
      Header,
      Careers,
      Footer
    }
  };
  </script>
  
  <style>
  .DOCU {
    color:red;
  }
  /* You can add some styles here if needed */
  </style>
  