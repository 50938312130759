<template>
  <Header activeTab="setup" isOpaque=true />
  <div class="our-story-container">
    <p>
      Our undertaking started with the desire to combat global mental health issues and general loss of sanity induced by algorithmic feeds.
      The way information flows in a society is crucial to determine which topics are relevant discussing and which are not.
      After all, our energy is limited, as individuals and as a society, so we need to make sure to use this energy wisely.<br>
      The algorithmic feeds of modernity are tied to the business model of advertising.<br><br>
      This brings with it the incentive to construct algorithms in such a way that they maximize engagement and time spent on the app.
      However, these feeds have also muddled the "Signal". Where the "Signal" is that which creates unity, consensus amongst all and an objective reality.
      On top of that, they have shifted power to those who hold money. If money gets to vote on whats important in the digital world, we have no
      digital democracy. This problem has recently only gotten worse with masses of bot farms.<br>
      Considering that we have all machinery we need to live a good life and also all the soil and all the people, what will bring us down, the greatest threat
      to humanity, is information degeneracy. If the information in the digital space, in communication, in society, gets distorted and the complexity
      of society thus grows at a higher pace than can be digested, this will inevitably lead to doomfall.<br><br>
      Then, if better information flow is so scrucial, why are there so few relevant social apps, why is the space such an oligopoly?<br>
      It is due to the Sun-Microsystems-Conspiracy. <br>
      See, all problems of distributed systems were already solved way in the past, way back. Actually, one of the first solutions to provide
      real-time-communication (Telephony, SMS) solved all of it. <br>
      However, with their billions of advertising budget, Sun Microsystems and other companies made sure to install "Object Oriented Programming" styles
      as the standard paradigm for programming in education institutions. The problem with this is that, object oriented programming, is an invented and inferior way of thinking,
      one that inevitably breeds headache when dealing with distributed systems or parallel programming.<br>
      OOP is sequential in nature. You often have things like static variables (shared memory across context).<br>
      All headache in distributed systems and parallel programming comes from shared memory.<br>
      However, now developers have to build distributed systems all the time in order to scale their backends, provide availability and fault tolerance
      and distribution. But since they are working with OOP and many never even heard of distributed systems, they simply are not able to provide such functionality.
      Unless. They build it on the cloud.<br>
      Enter the modern clusterfuck ecosystem of Kubernetes Docker Containers flying around Clouds and draining poor developers and companies of their last shirt with their
      abhorrent cloud computing fees.<br>
      Multiple times over the last years I have observed how some new social apps become popular, get traction, and then go broke to their cloud computing bill.<br><br>
      From the point of users, the modern ecosystem of apps is tiresome and redundant. Users log in with a bunch of different accounts on different platforms.<br>
      And since they instantly have to do some complex email verification if they enter their password wrong once, they are encouraged to reuse the same passwords across all apps.<br>
      And also, because nobody actually wants to remember a multitude of different passwords.<br>
      Just the fact that different messengers can not write to each other creates division.<br><br><br>
      Now, this is the state of the ecosystem. Making a relevant change to this requires not the creation of yet another social app, but of a new social ecosystem.<br>
      We solved fundamentally difficult problem that neccessarily comes up in any social app: The problem of shared state. We identified a new pattern of developing social apps
      that can speed up your development process. We give you the tools to create social apps easily, without having to learn the intricacies of distributed systems, without having
      to implement your Auth Logic and without you needing to deal with end-to-end-encryption.<br>
      Our icing on the cake is allowing you to create geo-features, like placing event venues or people and querying nearby entities.<br>
      After all, if we want to know if we are dreaming, the sense we use is touch. Touch grounds us to reality. Touch is what no social app can offer.<br>
      But working more with what is physically your environment may be a great start.<br><br>
      We plan on allowing you to collaborate with other developers.<br> 
    </p>
  </div>
</template>

<script>
import Header from '@/components/HeaderComponent.vue'; // Adjust the path as necessary

export default {
  name: 'OurStory',
  components: {
    Header,
  },
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.our-story-container {
  padding: 20px;
  margin: 0 auto;
  max-width: 800px;
  font-family: 'Roboto', sans-serif;
  line-height: 1.6;
  text-align: left;
  font-size: 1.6rem;
}
</style>
