<template>
  <Header activeTab="pricing" :isOpaque="false" />
  <Header activeTab="pricing" :isOpaque="false" />
  <section class="locality-pricing-page">
    <div class="pricing-container">
      <div class="text-center mb-20">
        <h1>Pricing</h1>
        <p>
          Choose the plan that suits your needs.<br> Pricing is based on the number of emitted events.<br>
          Scalability is ensured.
        </p>
        <div class="toggle-button-group">
          <button 
            class="toggle-button"
            :class="{ active: !isAnnual }"
            @click="isAnnual = false"
          >
            Monthly
          </button>
          <button 
            class="toggle-button"
            :class="{ active: isAnnual }"
            @click="isAnnual = true"
          >
            Annually
          </button>
        </div>
      </div>
      
      <div class="grid-container">
        <div v-for="(plan, index) in pricingPlans" :key="index" class="grid-item">
          <h2>{{ plan.name }}</h2>
          <h3>
            {{ isAnnual ? plan.annualPrice : plan.monthlyPrice }}
            <span v-if="plan.name !== 'Enterprise'">/{{ isAnnual ? 'year' : 'mo' }}</span>
          </h3>
          <ul class="styled-list">
            <li v-for="(feature, idx) in plan.features" :key="idx">{{ feature }}</li>
          </ul>
          <a 
            :href="plan.link" 
            @click.prevent="handlePlanClick(plan.link)" 
            class="cta-button"
          >
            Select
          </a>
          <p>{{ plan.description }}</p>
        </div>
      </div>
    </div>

    <!-- Login Dialog -->
    <v-dialog v-model="loginDialog" max-width="700px">
      <v-card class="login-card">
        <LoginRegister @close="closeLoginDialog" />
      </v-card>
    </v-dialog>
  </section>
  <Footer />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Header from '@/components/HeaderComponent.vue';
import Footer from '@/components/Footer.vue';
import LoginRegister from './LoginRegister.vue';

export default {
  name: 'Pricing',
  components: {
    Header,
    Footer,
    LoginRegister
  },
  data() {
    return {
      isAnnual: false, // Toggle for pricing plans
      loginDialog: false,
      redirectAfterLogin: null,
      pricingPlans: [
        {
          name: 'Free',
          monthlyPrice: '0€',
          annualPrice: '0€',
          features: [
            'Up to 2000 free events/day',
            '3 months storage of events',
            'Community Support',
          ],
          description: 'For individuals and small projects.',
          link: 'https://yourdomain.com/signup/free'
        },
        {
          name: 'Startup',
          monthlyPrice: '10€',
          annualPrice: '120€',
          features: [
            'Up to 35,000 free events/day',
            'Then 0.03€ for each further 1000 events',
            'Standard Support',
          ],
          description: 'Perfect for growing startups.',
          link: 'https://buy.stripe.com/9AQ9B01no2s4fmgaEE'
        },
      ]
    };
  },
  computed: {
    ...mapGetters(['isLoggedIn'])
  },
  methods: {
    ...mapActions(['logout']),
    showLoginDialog() {
      this.loginDialog = true;
    },
    closeLoginDialog() {
      this.loginDialog = false;
      if (this.redirectAfterLogin) {
        window.location.href = this.redirectAfterLogin;
        this.redirectAfterLogin = null;
      }
    },
    handlePlanClick(link) {
      if (this.isLoggedIn) {
        window.location.href = link;
      } else {
        this.redirectAfterLogin = link;
        this.showLoginDialog();
      }
    }
  }
};
</script>

<style scoped>

.locality-pricing-page {
  background: linear-gradient(to bottom, #414345, #414345 10%);
  color: #bbb1b8;
  font-family: 'Montserrat', sans-serif;
  padding: 40px 20px;
  position: relative;
}

.pricing-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Place gradients behind content */
  background: linear-gradient(135deg, rgba(255, 105, 180, 0.3), rgba(0, 255, 0, 0.3));
}

.pricing-container {
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 60px;
  text-align: center;
  position: relative;
}

h1 {
  font-size: 48px;
  color: #bbb1b8;
  margin-bottom: 40px;
  text-decoration: underline;
}

h2 {
  font-size: 36px;
  color: #bbb1b8;
  margin-bottom: 30px;
  text-decoration: underline;
}

h3 {
  font-size: 24px;
  color: #bbb1b8;
  margin-bottom: 15px;
}

p {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 30px;
}

.toggle-container {
  margin-top: 40px;
  display: flex;
  justify-content: center; /* Center the toggle buttons */
}

.toggle-button-group {
  display: flex;
  justify-content: center; /* Center the buttons horizontally */
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 40px;
  width: fit-content; /* Ensure the toggle group only takes necessary space */
  margin-left: auto;
  margin-right: auto;
}

.toggle-button {
  padding: 12px 24px;
  color: #bbb1b8;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.toggle-button.active {
  background-color: #ec38bc;
  color: white;
}

.toggle-button:hover {
  background-color: #a00075;
  color: white;
}

.grid-container {
  display: flex; /* Use flexbox to align cards */
  justify-content: center; /* Center the cards horizontally */
  flex-wrap: wrap; /* Allow cards to wrap on smaller screens */
  gap: 20px;
  margin-top: 40px;
}

.grid-item {
  max-width: 400px; /* Max width for each card */
  width: 100%; /* Make sure the cards are responsive */
  padding: 20px;
  border-radius: 10px;
  backdrop-filter: blur(20px); /* Blur effect */
  background-color: rgba(255, 255, 255, 0.1); /* Transparent background */
  color: white;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

/* Add radial gradient to cards for a soft glow effect */
.grid-item:nth-child(odd) {
  background: radial-gradient(circle, rgba(255, 105, 180, 0.2), transparent);
}

.grid-item:nth-child(even) {
  background: radial-gradient(circle, rgba(57, 255, 20, 0.2), transparent);
}

.styled-list {
  list-style-type: disc;
  padding-left: 20px;
}

.styled-list li {
  margin-top: 10px;
}

.cta-button {
  width: 240px; /* Full width buttons */
  text-align: left; /* Left-align text */
  padding: 12px 24px;
  margin-bottom: 10px;
  margin-top: 10px;
  background-color: #c0660044;
  color: white;
  font-weight: 600;
  border-radius: 6px;
  transition: background-color 0.3s ease;
  text-decoration: none;
  margin-top: 20px;
}

.cta-button:hover {
  background-color: #f8e10db0;
  transition: 0.3s ease;
  color: #120020fa;
}

/* Gradient background behind the cards */
.locality-pricing-page::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2; /* Behind the blur and cards */
  background: radial-gradient(circle, rgba(255, 105, 180, 0.3), transparent 60%), 
              radial-gradient(circle, rgba(57, 255, 20, 0.3), transparent 60%);
}

@media (max-width: 768px) {
  h1 {
    font-size: 36px;
  }

  p {
    font-size: 14px;
  }

  .cta-button {
    padding: 10px 20px;
    font-size: 16px;
  }

  .grid-container {
    display: block; /* Stack the cards vertically on small screens */
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
