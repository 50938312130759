<template>
  <footer class="footer">
    <div class="curve">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" preserveAspectRatio="none">
        <path :fill="fillColor" fill-opacity="1" d="M0,192L48,176C96,160,192,128,288,128C384,128,480,160,576,165.3C672,171,768,149,864,149.3C960,149,1056,171,1152,176C1248,181,1344,171,1392,165.3L1440,160L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path>
      </svg>
    </div>
    <div class="container mx-auto px-5 py-24">
      <div class="flex flex-wrap md:text-left text-center">
        <div class="lg:w-1/5 md:w-1/2 w-full px-4 mb-10">
          <h2 class="title-font font-medium text-white tracking-widest text-sm mb-3">ARTIFACTS</h2>
          <nav class="list-none mb-10">
            <li>
              <a href="https://pub.dev/publishers/locality.media/packages" class="text-gray-300 hover:text-white">Libraries</a>
            </li>
          </nav>
        </div>
        <div class="lg:w-1/5 md:w-1/2 w-full px-4 mb-10">
          <h2 class="title-font font-medium text-white tracking-widest text-sm mb-3">SUPPORT</h2>
          <nav class="list-none mb-10">
            <li>
              <router-link to="/for-investors" class="text-gray-300 hover:text-white">Invest</router-link>
            </li>
            <li>
              <router-link to="/careers" class="text-gray-300 hover:text-white">Collaborate</router-link>
            </li>
            <li>
              <a href="https://ko-fi.com/localitymedia" class="text-gray-300 hover:text-white">Donate</a>
            </li>
          </nav>
        </div>
        <div class="lg:w-1/5 md:w-1/2 w-full px-4 mb-10">
          <h2 class="title-font font-medium text-white tracking-widest text-sm mb-3">LEGAL</h2>
          <nav class="list-none mb-10">
            <li>
              <router-link to="/impressum" class="text-gray-300 hover:text-white">Impressum</router-link>
            </li>
            <li>
              <router-link to="/datenschutz" class="text-gray-300 hover:text-white">Data Protection</router-link>
            </li>
            <li>
              <router-link to="/gdpr" class="text-gray-300 hover:text-white">GDPR</router-link>
            </li>
          </nav>
        </div>
        <div class="lg:w-1/5 md:w-1/2 w-full px-4 mb-10">
          <h2 class="title-font font-medium text-white tracking-widest text-sm mb-3">CONTACT</h2>
          <nav class="list-none mb-10">
            <li>
              <router-link to="/contact" class="text-gray-300 hover:text-white">Contact Us</router-link>
            </li>
            <li>
              <a href="mailto:info@locality.media" class="text-gray-300 hover:text-white">info@locality.media</a>
            </li>
          </nav>
        </div>
        <div class="lg:w-1/5 md:w-1/2 w-full px-4 mb-10">
          <h2 class="title-font font-medium text-white tracking-widest text-sm mb-3">SOCIAL</h2>
          <span class="inline-flex sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start">
            <a class="text-gray-300 hover:text-white">
              <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
              </svg>
            </a>
            <a class="ml-3 text-gray-300 hover:text-white">
              <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
              </svg>
            </a>
            <a class="ml-3 text-gray-300 hover:text-white">
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
              </svg>
            </a>
            <a class="ml-3 text-gray-300 hover:text-white">
              <svg fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="0" class="w-5 h-5" viewBox="0 0 24 24">
                <path stroke="none" d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"></path>
                <circle cx="4" cy="4" r="2" stroke="none"></circle>
              </svg>
            </a>
          </span>
        </div>
      </div>
    </div>
    <div class="bg-gray-800">
      <div class="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row">
        <p class="text-gray-300 text-sm text-center sm:text-left">© {{ currentYear }} LoM Locality Media UG (Limited) —
          <a href="https://locality.media/impressum" rel="noopener noreferrer" class="text-gray-400 ml-1" target="_blank">@malte</a>
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    fillColor: {
      type: String,
      default: '#414345'
    }
  },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    }
  }
}
</script>

<style scoped>
.footer {
  position: relative;
  background: linear-gradient(to bottom, #232526, #414345);
  color: white;
  padding-top: 160px; /* Increased top padding for the curve */
}

.curve {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.curve svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 160px; /* Increased height of the curve */
}

nav li {
  margin-bottom: 0.5rem;
}

@media (max-width: 640px) {
  .footer {
    padding-top: 80px;
  }
  
  .curve svg {
    height: 80px;
  }
}
</style>