<template>
  <div class="flex items-center justify-center h-screen w-screen bg-black text-white">
    <div class="p-5 text-left">
      <h1 class="text-4xl font-normal">
        Locality Social Cloud creates 
        <span class="text-purple-500">
          digital encrypted social spaces<br>
        </span>
      </h1>
      <h2 class="text-2xl font-normal">
        that allow you to build
        <span class="text-purple-500">
          serverless, 
        </span>
        <span class="text-orange-500">
          end-to-end-encrypted,
        </span>
        <span class="text-yellow-500">
          social,
        </span>
        <span class="text-green-500">
          live
        </span>
        Apps. <br>Whether it is <span class="text-purple-500">
          A game, 
        </span>
        <span class="text-orange-500">
          a messenger,
        </span>
        <span class="text-yellow-500">
          a collaboration tool,
        </span>
        <span class="text-green-500">
          or an orchestration tool for drones,
        </span>
        <br>Social Apps are about 
        <span class="text-2xl text-indigo-500">
          Shared Space.
        </span>
        Many observers observe the same 
        <span class="text-2xl text-indigo-500">
          Shared Space
        </span>
        and <br> interact with this in a manner that they <span class="text-yellow-500">
          agree
        </span> on the observed state of the shared space.
      </h2>

    </div>
  </div>
</template>

<script>
export default {
  name: 'PresentationSlide5',
}
</script>
