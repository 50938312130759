<template>
  <ServiceLayout
    title="Business Unification Suite"
    subtitle="Integrieren Sie alle Geschäftsprozesse in einer einzigen Anwendung für bessere Effizienz und Übersicht."
  >
    <!-- Hero Section -->
    <div class="bg-gradient-to-br from-gray-900 to-blue-900 p-8 rounded-xl mb-8">
      <h1 class="text-4xl font-bold text-white mb-4">
        Transformieren Sie Ihre Geschäftsprozesse
      </h1>
      <p class="text-xl text-blue-200 mb-6">
        Eine zentrale Lösung für alle Ihre Unternehmensanforderungen
      </p>
      <button class="bg-blue-500 hover:bg-blue-400 text-white px-8 py-3 rounded-lg font-semibold transition-all shadow-lg hover:shadow-blue-500/25">
        Jetzt Beratungsgespräch vereinbaren
      </button>
    </div>

    <!-- Feature Grid -->
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-12">
      <!-- Core Features Card -->
      <div class="bg-gray-800 rounded-xl shadow-lg p-6 border border-gray-700 hover:shadow-xl hover:shadow-blue-500/10 transition-all">
        <div class="flex items-center mb-4">
          <div class="w-12 h-12 bg-blue-900 rounded-full flex items-center justify-center">
            <i class="fas fa-star text-blue-400 text-xl"></i>
          </div>
          <h2 class="text-xl font-bold text-white ml-4">Core Features</h2>
        </div>
        <ul class="space-y-3">
          <li v-for="feature in features" :key="feature" class="flex items-start">
            <span class="text-green-400 mr-2">✓</span>
            <span class="text-gray-300">{{ feature }}</span>
          </li>
        </ul>
      </div>

      <!-- Implementation Timeline Card -->
      <div class="bg-gray-800 rounded-xl shadow-lg p-6 border border-gray-700 hover:shadow-xl hover:shadow-blue-500/10 transition-all">
        <div class="flex items-center mb-4">
          <div class="w-12 h-12 bg-purple-900 rounded-full flex items-center justify-center">
            <i class="fas fa-clock text-purple-400 text-xl"></i>
          </div>
          <h2 class="text-xl font-bold text-white ml-4">Implementation Process</h2>
        </div>
        <div class="space-y-4">
          <div v-for="(item, index) in timeline" :key="index" class="relative pl-8 border-l-2 border-gray-600 pb-4 last:pb-0">
            <div class="absolute -left-[5px] top-0 w-2 h-2 rounded-full bg-blue-400"></div>
            <h3 class="font-semibold text-blue-300">{{ item.month }}</h3>
            <p class="text-gray-400">{{ item.description }}</p>
          </div>
        </div>
      </div>

      <!-- Investment Card -->
      <div class="bg-gradient-to-br from-blue-900 to-blue-800 rounded-xl shadow-lg p-6 text-white">
        <div class="flex items-center mb-4">
          <div class="w-12 h-12 bg-blue-700 rounded-full flex items-center justify-center">
            <i class="fas fa-euro-sign text-white text-xl"></i>
          </div>
          <h2 class="text-xl font-bold ml-4">Investment</h2>
        </div>
        <div class="text-3xl font-bold mb-4">Ab €80K</div>
        <p class="mb-4 text-blue-200">Flexible Zahlungsoptionen für Unternehmen verfügbar</p>
        <ul class="space-y-2">
          <li v-for="option in paymentOptions" :key="option" class="flex items-center text-blue-200">
            <span class="mr-2">•</span>
            <span>{{ option }}</span>
          </li>
        </ul>
      </div>
    </div>
    <!-- Detailed Description -->
    <div class="bg-gray-800 rounded-xl shadow-lg p-8 border border-gray-700">
      <h2 class="text-2xl font-bold text-white mb-6">
        Warum unsere Business Unification Suite eine kluge Wahl ist
      </h2>
      <div class="grid-container">
        <div v-for="(service, index) in services3" :key="index" class="grid-item">
          <h3>{{ service.title }}</h3>
          <ul class="styled-list">
            <li v-for="(item, itemIndex) in service.items" :key="itemIndex">
              {{ item }}
            </li>
          </ul>
        </div>
      </div>
      <div class="space-y-4 text-gray-300">
        <p v-for="(paragraph, index) in description" :key="index">
          {{ paragraph }}
        </p>
      </div>
      <ConsultingCallQuestions/>
      <div class="mt-8 flex justify-center">
        <button class="bg-blue-500 hover:bg-blue-400 text-white px-8 py-3 rounded-lg font-semibold transition-all shadow-lg hover:shadow-blue-500/25">
          Kontaktieren Sie uns
        </button>
      </div>
    </div>
  </ServiceLayout>
  <a href="/impressum" style="position: absolute; bottom: 20px; left: 20px; color: white;">Impressum</a>
</template>

<script>
import ServiceLayout from '@/components/ServiceLayout.vue'
import ConsultingCallQuestions from '@/components/ConsultingCallQuestions.vue'

export default {
  name: 'BusinessUnificationSuite',
  components: {
    ServiceLayout,
    ConsultingCallQuestions
  },
  data() {
    return {
      features: [
        'Integration aller Geschäftsprozesse in einer einzigen App',
        'Zugangskontroll-Management',
        'Nahtlose Systemintegration',
        'Flexibilität zur Anpassung an spezifische Anforderungen',
        'Optimierung der Betriebskosten'
      ],
      timeline: [
        { month: 'Monat 1', description: 'Anforderungsanalyse und Planung der Architektur' },
        { month: 'Monat 2-3', description: 'Entwicklung der Kernelemente und Systemintegration' },
        { month: 'Monat 4', description: 'Testphase und Feinabstimmung' },
        { month: 'Monat 5', description: 'Deployment und Schulung des Teams' },
        { month: 'Monat 6', description: 'Nachbetreuung und Optimierung' }
      ],
      paymentOptions: [
        'Monatliche Zahlungspläne',
        'Optionen für Teilzahlung',
        'Leistungsbasierte Preismodelle'
      ],
      description: [
        'In der heutigen schnelllebigen Geschäftswelt ist die Fähigkeit, Prozesse zu integrieren und zu optimieren, entscheidend für den Erfolg. Unser Business Unification Suite bietet Unternehmen eine umfassende Lösung, die es ermöglicht, alle wesentlichen Geschäftsprozesse in einer einzigen, benutzerfreundlichen Anwendung zu vereinen.',
        'Durch die Implementierung eines integrierten Systems reduzieren Sie die Komplexität Ihrer IT-Infrastruktur und minimieren potenzielle Fehlerquellen, die bei der Nutzung mehrerer isolierter Anwendungen auftreten können.',
        'Die Flexibilität unseres Systems ermöglicht es, Anpassungen vorzunehmen, die auf die spezifischen Bedürfnisse Ihres Unternehmens zugeschnitten sind.',
        'Investieren Sie in die Zukunft Ihres Unternehmens und steigern Sie Ihre Effizienz durch nahtlose Integration aller Geschäftsprozesse.'
      ], 
      services3: [
        {
          title: 'Business Integration',
          items: [
            'SAP Integration',
            'Payment Systems Integration',
            'Custom ERP/CRM Integration',
            'Database Integration',
            'Data Migration',
            'Custom Dashboards'
          ]
        },
        {
          title: 'Process Integration',
          items: [
            'Digitalize existing processes',
            'NFC / QR Code Integration',
            'Telephony Integration',
            'SMS Integration',
            'Email Integration',
            'Geospatial Features'
          ]
        },
        {
          title: 'Technology Integration',
          items: [
            'REST API Integration',
            'OAuth2 API Integration',
            'ChatGPT Integration',
            'Discord Integration',
            'WhatsApp Integration',
            'Social Media Integration'
          ]
        }
      ],
    }
  }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-bottom: 30px;
}

/* Add transparency, gradient background, and blur effect to each card */
.grid-item {
  padding: 20px;
  border-radius: 0px;
  color: white;
  backdrop-filter: blur(20px); /* Blur effect */
  background-color: rgba(255, 255, 255, 0.1); /* Transparency */
}

/* Radial gradient backgrounds for different cards */
.grid-item:nth-child(1) {
  background: radial-gradient(circle, rgba(255, 182, 193, 0.3), transparent);
}

.grid-item:nth-child(2) {
  background: radial-gradient(circle, rgba(57, 255, 20, 0.3), transparent);
}

.grid-item:nth-child(3) {
  background: radial-gradient(circle, rgba(173, 216, 230, 0.3), transparent);
}
.lower:nth-child(1) {
  background: radial-gradient(circle, rgba(172, 29, 160, 0.61), transparent);
}
.lower:nth-child(2) {
  background: radial-gradient(circle, rgba(230, 226, 24, 0.3), transparent);
}
.lower:nth-child(3) {
  background: radial-gradient(circle, rgba(24, 150, 223, 0.3), transparent);
}

</style>