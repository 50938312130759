<template>
  <v-app>
    <!-- Header -->
    <v-app-bar app>
      <v-tabs>
        <v-tab to="/admin" exact>Admin Page</v-tab>
        <v-tab to="/public">Public Page</v-tab>
      </v-tabs>
    </v-app-bar>

    <v-main>
      <v-container>
        <v-row>
          <v-col cols="12">

            <!-- Analytics Section -->
            <v-card class="mb-4">
              <v-card-title>
                <span class="headline">Traffic Analytics</span>
              </v-card-title>
              <v-card-subtitle>
                Total User Logins: {{ totalLogins }}
                <br />
                Total User Registrations: {{ totalRegistrations }}
              </v-card-subtitle>
              <v-card-text>
                <h3>Events per Day:</h3>
                <ul>
                  <li v-for="(count, date) in eventsPerDay" :key="date">
                    {{ date }}: {{ count }} events
                  </li>
                </ul>
              </v-card-text>
            </v-card>

            <!-- Developers and Users Section -->
            <v-card>
              <v-card-title>
                <span class="headline">{{ showDevelopers ? 'Developers' : 'Users' }}</span>
                <v-spacer></v-spacer>
                <v-btn @click="toggleView" color="primary">
                  Switch to {{ showDevelopers ? 'Users' : 'Developers' }}
                </v-btn>
              </v-card-title>
              <v-data-table
                :headers="showDevelopers ? developerHeaders : userHeaders"
                :items="showDevelopers ? developers : users"
                item-key="id"
                class="elevation-1"
              ></v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  computed: {
    accessToken() {
      return this.$store.state.accessToken; // Assuming you store the token in Vuex
    }
  },
  data() {
    return {
      baseUrl: 'http://localhost:4000',
      totalLogins: 0,
      totalRegistrations: 0,
      eventsPerDay: {},
      showDevelopers: true,
      developerHeaders: [
        { title: 'Company', key: 'company_id' },
        { title: 'Email', key: 'email' },
      ],
      userHeaders: [
        { title: 'Name', key: 'user_id' },
        { title: 'Public Key', key: 'public_key' },
      ],
      developers: [],
      users: [],
    };
  },
  methods: {
    getAuthHeaders() {
      return {
        'Authorization': `Bearer ${this.accessToken}`,
        'Content-Type': 'application/json',
      };
    },
    toggleView() {
      this.showDevelopers = !this.showDevelopers;
    },
    async markAsDone(id) {
      try {
        await fetch(`${this.baseUrl}/admin/gdpr-requests/${id}/mark-done`, { 
          method: 'POST',
          headers: this.getAuthHeaders(),
        });
        this.gdprRequests = this.gdprRequests.map(request =>
          request.id === id ? { ...request, done: true } : request
        );
      } catch (error) {
        console.error('Error marking request as done:', error);
      }
    },
    async fetchAnalytics() {
      try {
        const [analyticsResponse, eventsResponse] = await Promise.all([
          fetch(`${this.baseUrl}/admin/analytics`, { headers: this.getAuthHeaders() }),
          fetch(`${this.baseUrl}/admin/events-per-day`, { headers: this.getAuthHeaders() }),
        ]);

        const analyticsData = await analyticsResponse.json();
        alert(JSON.stringify(analyticsData));
        this.totalLogins = analyticsData.totalLogins;
        this.totalRegistrations = analyticsData.totalRegistrations;
        this.eventsPerDay = await eventsResponse.json();
      } catch (error) {
        console.error('Error fetching analytics:', error);
      }
    },
    async fetchDevelopersAndUsers() {
      try {
        const [developersResponse, usersResponse] = await Promise.all([
          fetch(`${this.baseUrl}/admin/developers`, { headers: this.getAuthHeaders() }),
          fetch(`${this.baseUrl}/admin/users`, { headers: this.getAuthHeaders() }),
        ]);

        const developersData = await developersResponse.json();
        const usersData = await usersResponse.json();

        this.developers = Array.isArray(developersData) ? developersData : [];
        this.users = Array.isArray(usersData) ? usersData : [];
      } catch (error) {
        console.error('Error fetching developers and users:', error);
      }
    },
    async fetchGDPRRequests() {
      try {
        const response = await fetch(`${this.baseUrl}/admin/gdpr-requests`, { headers: this.getAuthHeaders() });
        this.gdprRequests = await response.json();
      } catch (error) {
        console.error('Error fetching GDPR requests:', error);
      }
    },
  },
  created() {
    this.fetchAnalytics();
    this.fetchDevelopersAndUsers();
    this.fetchGDPRRequests();
  },
};
</script>

<style scoped>
.headline {
  font-size: 1.5em;
}
</style>