<template> 

  <Header activeTab="gdpr" isOpaque=true background="true"  />  
   <Header />
    <v-container class="fill-height">
      <v-row justify="center" align="center">
        <v-col cols="12" md="8" lg="6">
          <v-card class="pa-5">
            <v-card-title class="headline">Impressum</v-card-title>
            <v-card-text>
              <v-divider class="mb-4"></v-divider>
              <v-list dense>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Company Name:</v-list-item-title>
                    <v-list-item-subtitle>LoM Locality Media UG (Limited)</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Address:</v-list-item-title>
                    <v-list-item-subtitle>Kreuzberger Str. 9, 33619 Bielefeld, Germany</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Email:</v-list-item-title>
                    <v-list-item-subtitle>contact@locality.media</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Commercial Register:</v-list-item-title>
                    <v-list-item-subtitle>HRB 44888 Bielefeld</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>VAT ID:</v-list-item-title>
                    <v-list-item-subtitle>VAT Number: DE354810166</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Managing Director:</v-list-item-title>
                    <v-list-item-subtitle>Malte Nolden</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <Footer fillColor="#ffffff" />
  </template>
  
  <script>  
import Header from '@/components/HeaderComponent.vue'; // Adjust the path as necessary
import Footer from '@/components/Footer.vue';

export default {
  components: {
    Header,
    Footer,
  },
  name: 'Impressum',
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
  mounted() {
    // Scroll to the top of the page over 1 second if scrolled down
    if (window.scrollY > 0) {
      window.scrollTo({
        top: 100,
        behavior: 'smooth' // Smooth scrolling effect
      });
    }
  },
};
</script>

<style scoped>
.fill-height {
  height: 100vh;
}
</style>
  