<template>
  <section class="locality-pricing-page">
    <div class="pricing-container">
      <div class="text-center mb-20">
        <p>
          Choose the plan that suits your business.<br> We offer solutions for businesses of all sizes, from small startups to large global enterprises.<br>
          Even as you grow, we got your back covered.
        </p>
      </div>
      <div class="grid-container">

        <div v-if="selectedPlan === 'startup'" class="grid-item">
          <h2>Social App Development</h2>
          <h3>€20K - 80K +28% equity</h3>
          <ul class="styled-list">
            <li>Custom social media app development</li>
            <li>User authentication and profile management</li>
            <li>Chat and messaging functionalities</li>
            <li>Story creation and feed functionalities</li>
            <li>End-to-End-encryption</li>
            <li>Multi-user collaboration</li>
            <li>Cross-device cross-platform</li>
            <li>Ongoing support</li>
            <li>2-month implementation MVP</li>
          </ul>
          <br>
          <p>Build connections and community with a tailored social app.</p>
          <button class="learn-more-button" @click="learnMore('social-app')">
            Learn More
          </button>
        </div>
        <div v-if="selectedPlan === 'microservices'" class="grid-item">
          <h2>Locality CyberSec Certification Training</h2>
          <h3>€249</h3>
          Train your developers to become experts of Cybersecurity. Gain access to our Cybersecurity newsletter.
          <ul class="styled-list">
            <li>Access to 8 hours of video material and 12 articles for all employees of your company</li>
            <li>Stay updated with our High Quality Cyber Security News Letter (CySNewL)</li>
            <li>Domain knowledge in all areas of Cybersecurity</li>
            <li>Send up to 3 emails with clarification questions to us.</li>
            <li>Certifications available</li>
          </ul>
          <br>
          <p>Secure your business against cyberattacks with our comprehensive audit services.</p>
          <button class="learn-more-button" @click="learnMore('cybersecurity-course')">
            Learn More
          </button>
        </div>

        <div v-if="selectedPlan === 'microservices'" class="grid-item">
          <h2>Dashboard</h2>
          <h3>Ab €5K</h3>
          Process and visualize your data in realtime.
          <ul class="styled-list">
            <li>Authentification</li>
            <li>Integration with one core system (ERP, CRM, server logs)</li>
            <li>Deployment</li>
            <li>1-month engagement</li>
          </ul>
          <br>
          <p>Secure your business against cyberattacks with our comprehensive audit services.</p>
          <button class="learn-more-button" @click="learnMore('dashboard')">
            Learn More
          </button>
        </div>

        <div v-if="selectedPlan === 'kmu'" class="grid-item">
          <h2>Cybersecurity Audit</h2>
          <h3>Ab €15K</h3>
          <ul class="styled-list">
            <li>Assessment of OpSec: Data Availability, Confidentiality, Integrity</li>
            <li>Penetration testing and encryption assessments</li>
            <li>Employee Training</li>
            <li>Cybersecurity Newsletter</li>
            <li>Locality Certification assessment</li>
            <li>2-month engagement</li>
          </ul>
          <br>
          <p>Secure your business against cyberattacks with our comprehensive audit services.</p>
          <button class="learn-more-button" @click="learnMore('cybersecurity-audit')">
            Learn More
          </button>
        </div>


        <!-- KMU Offerings -->
        <div v-if="selectedPlan === 'kmu'" class="grid-item">
          <h2>Business Unification Suite</h2>
          <h3>Ab €80K</h3>
          <ul class="styled-list">
            <li>Integrate all business processes into one app</li>
            <li>Access management and system integration</li>
            <li>4-month implementation</li>
          </ul>
          <p>Unify your operations for better efficiency and oversight. Price varies based on project complexity and integration needs.</p>
          <button class="learn-more-button" @click="learnMore('business-suite')">
            Learn More
          </button>
        </div>

      </div>
    </div>
  </section>
  <Footer />
</template>

<script>
export default {
  name: 'Packages',
  components: {},
  data() {
    return {
      selectedPlan: 'kmu',
      offerLinks: {
        'startup-booster': '/services/startup-booster',
        'social-app': '/services/social-app',
        'cybersecurity-audit': '/services/cybersecurity',
        'cybersecurity-course': '/services/cybersecurity-course',
        'dashboard': '/services/dashboard',
        'business-suite': '/services/business-suite',
        'privacy-shield': '/services/privacy-shield',
        'private-cloud': '/services/private-cloud'
      }
    };
  },
  methods: {
    learnMore(offerId) {
      const link = this.offerLinks[offerId];
      if (link) {
        window.location.href = link;
      }
    }
  }
};
</script>

<style scoped>
.locality-pricing-page {
  background: linear-gradient(to bottom, #414345, #414345 10%);
  color: #bbb1b8;
  font-family: 'Montserrat', sans-serif;
  padding: 40px 20px;
  position: relative;
}

.pricing-container {
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 60px;
  text-align: center;
  position: relative;
}

h2 {
  font-size: 28px;
  color: #bbb1b8;
  margin-bottom: 20px;
  text-decoration: underline;
}

h3 {
  font-size: 20px;
  color: #bbb1b8;
  margin-bottom: 10px;
}

p {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 20px;
}

.toggle-button-group {
  display: flex;
  justify-content: center; 
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 40px;
  width: fit-content; 
  margin-left: auto;
  margin-right: auto;
}

.toggle-button {
  padding: 12px 24px;
  color: #bbb1b8;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 140px;
}

.toggle-button.active {
  background-color: #ec38bc;
  color: white;
}

.toggle-button:hover {
  background-color: #a00075;
  color: white;
}

.grid-container {
  display: flex; 
  justify-content: center; 
  flex-wrap: wrap; 
  gap: 20px;
  margin-top: 40px;
}

.grid-item {
  max-width: 500px; 
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  backdrop-filter: blur(20px); 
  background-color: rgba(255, 255, 255, 0.1); 
  color: white;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.grid-item:nth-child(odd) {
  background: radial-gradient(circle, rgba(255, 105, 180, 0.2), transparent);
}

.grid-item:nth-child(even) {
  background: radial-gradient(circle, rgba(57, 255, 20, 0.2), transparent);
}

.styled-list {
  list-style-type: disc;
  padding-left: 20px;
}

.styled-list li {
  margin-top: 10px;
}

.learn-more-button {
  padding: 12px 24px;
  background-color: #ec38bc;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  font-size: 16px;
  transition: all 0.3s ease;
  margin-top: 20px;
  width: 100%;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.learn-more-button:hover {
  background-color: #a00075;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(236, 56, 188, 0.3);
}

.learn-more-button:active {
  transform: translateY(0);
  box-shadow: none;
}
</style>