<template>
    <div class="sales-dashboard">
      <!-- Success Stories Header -->
      <h2 class="text-2xl font-bold text-white mb-6">
        Erfolgsgeschichten unserer Kunden
      </h2>
  
      <!-- Use Cases Section -->
      <div class="space-y-8 mb-8">
        <!-- Cybersecurity Use Case -->
        <UseCaseCard 
          :icon="'fas fa-shield-alt'"
          :iconBgClass="'bg-red-900/30'"
          :iconColorClass="'text-red-400'"
          title="DDoS Defense Monitor"
          description="Ein führender E-Commerce-Anbieter nutzt unser Dashboard zur Erkennung und Abwehr von DDoS-Angriffen."
          :highlights="cybersecurityHighlights"
          :metrics="securityMetrics"
          :chartData="ddosData"
          chartType="LineChart"
          chartTitle="Netzwerkverkehr Anomalien"
          alert="DDoS Verdacht"
          alertIcon="fas fa-exclamation-triangle"
          alertClass="text-red-400 animate-pulse"
        />
  
        <!-- CRM Use Case -->
        <UseCaseCard 
          :icon="'fas fa-users'"
          :iconBgClass="'bg-blue-900/30'"
          :iconColorClass="'text-blue-400'"
          title="Business Growth Monitor"
          description="Ein mittelständisches Beratungsunternehmen visualisiert Kundenbeziehungen und Wachstumstrends."
          :highlights="crmHighlights"
          :metrics="crmMetrics"
          :chartData="lifecycleData"
          chartType="BarChart"
          chartTitle="Customer Lifecycle Stages"
        />
      </div>
    </div>
  </template>
  
  <script>
  import UseCaseCard from './UseCaseCard.vue'; // Reusable card component
  
  export default {
    name: 'SalesDashboard',
    components: {
      UseCaseCard
    },
    data() {
      return {
        // Highlights for different use cases
        cybersecurityHighlights: [
          '95% schnellere Angriffserkennung',
          'Automatische Mitigation-Maßnahmen',
          '24/7 Echtzeitüberwachung'
        ],
        crmHighlights: [
          '+45% Kundenretention',
          'Verbesserte Lead-Konversion',
          'Datenbasierte Entscheidungen'
        ],
        // DDoS Security Metrics
        ddosData: [
          { time: '00:00', normal: 1000, attack: 1200 },
          { time: '04:00', normal: 1100, attack: 4500 },
          { time: '08:00', normal: 1050, attack: 8000 },
          { time: '12:00', normal: 1200, attack: 12000 },
          { time: '16:00', normal: 1150, attack: 15000 },
          { time: '20:00', normal: 1000, attack: 9000 },
          { time: '23:59', normal: 950, attack: 3000 }
        ],
        securityMetrics: [
          { title: 'Aktuelle Anfragen/s', value: '15,234', color: 'text-red-400' },
          { title: 'Geblockte IPs', value: '1,234', color: 'text-green-400' },
          { title: 'Latenz', value: '45ms', color: 'text-white' }
        ],
  
        // CRM Metrics
        crmMetrics: [
          { title: 'Neue Kunden (MTD)', value: '234', trend: 'up', change: '+12%' },
          { title: 'Durchschn. Deal-Größe', value: '€15.5K', trend: 'up', change: '+8%' },
          { title: 'Customer LTV', value: '€45.2K', trend: 'up', change: '+15%' },
          { title: 'Kundenzufriedenheit', value: '94%', trend: 'stable', change: 'Stabil' }
        ],
        lifecycleData: [
          { stage: 'Lead', count: 2450 },
          { stage: 'Opportunity', count: 1234 },
          { stage: 'Negotiation', count: 857 },
          { stage: 'Customer', count: 3256 },
          { stage: 'Advocate', count: 945 }
        ]
      };
    }
  }
  </script>
  
  <style scoped>
  .sales-dashboard {
    padding: 2rem;
    background-color: #1a202c;
    min-height: 100vh;
  }
  </style>
  