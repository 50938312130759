<template>
  <div class="header-wrapper">
    <header class="headerClasses">
      <div class="blur-overlay"></div>

      <div v-if="background" style="background-color: #12131499; width: 100%; height: 70px; position: fixed;"></div>
      
        <!-- Burger Menu Button -->
        <button @click="toggleMenu" class="md:hidden absolute right-4 text-black focus:outline-none mt-9 mr-3">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#ffffff" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16m-7 6h7" />
          </svg>
        </button>

      <div class="container mx-auto flex items-center justify-center h-full">
        <!-- Navigation Links -->
        <nav class="hidden md:flex md:flex-row items-center justify-center">
          <a href="/" :class="{ 'active-tab': activeTab === 'locality' }" class="nav-link hover:text-orange-500">Locality</a>
          <a href="/setup" :class="{ 'active-tab': activeTab === 'setup' }" class="nav-link hover:text-orange-500">Setup</a>
          <a href="/documentation" :class="{ 'active-tab': activeTab === 'documentation' }" class="DOCU nav-link hover:text-orange-500">Documentation</a>
          <a href="/consulting" :class="{ 'active-tab': activeTab === 'consulting' }" class="nav-link hover:text-orange-500">Consulting</a>
          <a @click="closeMenu" href="/pricing" :class="{ 'active-tab': activeTab === 'pricing' }" class="nav-link hover:text-orange-500">Pricing</a>
        </nav>

        <!-- Login Button -->
        <login-button class="hidden md:block ml-6"></login-button>

      </div>
      <div v-if="isMenuOpen" style="background-color: #12131477; width: 100%; height: 2000px; position: absolute;"></div>

      <!-- Fullscreen Mobile Menu -->
      <transition name="fade">
        <div v-if="isMenuOpen" class="fixed inset-0 z-50 flex flex-col justify-center items-center">          

          <button @click="toggleMenu" class="absolute top-5 right-5 text-white">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-8 h-8">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
          <nav class="flex flex-col text-center space-y-4">
            <a @click="closeMenu" href="/" :class="{ 'active-tab': activeTab === 'locality' }" class="block text-2xl hover:text-orange-500">Locality</a>
            <a @click="closeMenu" href="/setup" :class="{ 'active-tab': activeTab === 'setup' }" class="block text-2xl  hover:text-orange-500">Setup</a>
            <a @click="closeMenu" href="/documentation" :class="{ 'active-tab': activeTab === 'documentation' }" class="block text-2xl hover:text-orange-500">Documentation</a>
            <a @click="closeMenu" href="/consulting" :class="{ 'active-tab': activeTab === 'consulting' }" class="block text-2xlhover:text-orange-50">Consulting</a>

            <a @click="closeMenu" href="/pricing" :class="{ 'active-tab': activeTab === 'pricing' }" class="block text-2xl hover:text-orange-500">Pricing</a>
          </nav>
          <div class="mt-8">
            <login-button></login-button>
          </div>
        </div>
      </transition>
    </header>
    <main :style="mainContentStyle">
      <slot></slot>
    </main>
  </div>
</template>

<script>
import LoginButton from './LoginButton.vue';

export default {
  name: 'Header',
  components: {
    LoginButton
  },
  props: {
    theme: {
      type: String,
      default: 'dark'
    },
    activeTab: {
      type: String,
      required: true
    },
    background: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isMenuOpen: false
    };
  },
  computed: {
    headerHeight() {
      return '60px';
    },
    mainContentStyle() {
      return {
        paddingTop: this.headerHeight
      };
    }
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
      if (this.isMenuOpen) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = '';
      }
    },
    closeMenu() {
      this.isMenuOpen = false;
      document.body.style.overflow = '';
    }
  }
};
</script>

<style scoped>
.header-wrapper {
  position: relative;
  width: 100%;
  height: 0;
}

.blur-overlay {
  position: absolute;
  top: 0; /* Adjust according to the SVG height and where you want the blur */
  left: 0;
  right: 0;
  height: 70px; /* Adjust to cover the area where the SVG and content meet */
  width: 100%;
  backdrop-filter: blur(25px); /* Gaussian blur effect */
  
}

.fixed {
  backdrop-filter: blur(25px); /* Gaussian blur effect */
  
}

.headerClasses {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px;
}

.container {
  max-width: 800px; /* Adjust this value to control the width of the centered content */
  width: 100%;
  position: relative;
}

nav {
  display: flex;
  justify-content: center;
  align-items: center;
}

nav a {
  color: white;
  font-size: 14px;
  padding: 0 15px; /* Adjust spacing between links */
}

nav .active-tab {
  color: #ffd16e;
}

.login-button {
  color: white;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.fixed.inset-0 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 880px) {
  .container {
    display: none;
    justify-content: center;
  }

  .nav-link {
    margin-right: 0.5rem;
  }

  .login-link {
    background-color: transparent;
    color: inherit;
    padding: 0;
    text-decoration: none;
    margin-right: 0.5rem;
  }

  .login-link:hover {
    background-color: transparent;
    color: #3d255e;
    text-decoration: underline;
  }
}
</style>