import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-23de3db4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "scrollable-container" }
const _hoisted_2 = { id: "presentation0" }
const _hoisted_3 = { id: "presentation1" }
const _hoisted_4 = { id: "presentation5" }
const _hoisted_5 = { id: "presentation2" }
const _hoisted_6 = { id: "presentation2" }
const _hoisted_7 = { id: "presentation4" }
const _hoisted_8 = { id: "setup" }
const _hoisted_9 = { id: "documentation" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_LandingPage = _resolveComponent("LandingPage")!
  const _component_PresentationSlide0 = _resolveComponent("PresentationSlide0")!
  const _component_PresentationSlide1 = _resolveComponent("PresentationSlide1")!
  const _component_PresentationSlide5 = _resolveComponent("PresentationSlide5")!
  const _component_PresentationSlide2 = _resolveComponent("PresentationSlide2")!
  const _component_PresentationSlide3 = _resolveComponent("PresentationSlide3")!
  const _component_PresentationSlide4 = _resolveComponent("PresentationSlide4")!
  const _component_Setup = _resolveComponent("Setup")!
  const _component_Documentation = _resolveComponent("Documentation")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Header, {
      activeTab: "locality",
      isOpaque: "true"
    }),
    _createVNode(_component_Header, {
      activeTab: "locality",
      isOpaque: "true"
    }),
    _createElementVNode("main", null, [
      _createVNode(_component_LandingPage),
      _createElementVNode("section", _hoisted_2, [
        _createVNode(_component_PresentationSlide0)
      ]),
      _createElementVNode("section", _hoisted_3, [
        _createVNode(_component_PresentationSlide1)
      ]),
      _createElementVNode("section", _hoisted_4, [
        _createVNode(_component_PresentationSlide5)
      ]),
      _createElementVNode("section", _hoisted_5, [
        _createVNode(_component_PresentationSlide2)
      ]),
      _createElementVNode("section", _hoisted_6, [
        _createVNode(_component_PresentationSlide3)
      ]),
      _createElementVNode("section", _hoisted_7, [
        _createVNode(_component_PresentationSlide4)
      ]),
      _createElementVNode("section", _hoisted_8, [
        _createVNode(_component_Setup)
      ]),
      _createElementVNode("section", _hoisted_9, [
        _createVNode(_component_Documentation)
      ])
    ]),
    _createElementVNode("footer", null, [
      _createVNode(_component_Footer)
    ])
  ]))
}