<template>
  <div class="for-investors">
    <h1>Investing in the Future of Social Media</h1>

    <section>
      <h2>The Challenge</h2>
      <p>
        Despite the increasing demand for social media applications, there are surprisingly few successful platforms available. The challenges associated with creating a new social media app are numerous and complex.
      </p>
      <ul>
        <li>
          <strong>High Expectations:</strong> Existing giants like Instagram and TikTok set a high bar. New apps must provide real innovation and a unique selling proposition (USP) to stand out, which requires substantial investment.
        </li>
        <li>
          <strong>Viral Potential:</strong> Social apps can go viral, but developers often lack the expertise to manage distributed systems. This reliance on cloud providers comes with high operational costs, and many startups have collapsed under the weight of unexpected expenses.
        </li>
        <li>
          <strong>Content vs. Social Dynamics:</strong> Social media apps face a delicate balance between content and social interaction. While media apps may struggle with content availability, social apps often lack the user base needed to overcome existing network effects.
        </li>
        <li>
          <strong>Cost Per User:</strong> The scalability of cloud services means higher costs per user. Established platforms like Facebook can afford inefficiencies, while newer apps must navigate a landscape that incentivizes a more social rather than media-focused approach.
        </li>
      </ul>
      <p>
        These challenges create a daunting ecosystem for both developers and users, leading to:
      </p>
      <ul>
        <li>Power imbalances driven by money and attention, leading to questionable decisions made by a few.</li>
        <li>Divided feeds that fragment our experience and obscure universal artistic expressions.</li>
        <li>The necessity for users to create multiple accounts and repeatedly add friends across platforms.</li>
        <li>Increased governmental oversight and compliance pressures, resulting in censorship and surveillance.</li>
      </ul>
      <p>
        It's time to put an end to this.
      </p>
    </section>

    <section>
      <h2>Our Vision</h2>
      <p>
        We have initiated this journey by building a proof-of-concept for an Instagram/WhatsApp-like social network, with scalability and user-centric design in mind. Through this process, we realized that we could do more than just create another app that falls victim to the same issues faced by existing platforms. We recognized the need for a shift in how social applications are developed.
      </p>
      <p>
        By simplifying the technical challenges, we can empower more developers to create social apps. Our goal is to accelerate development speed and establish a shared ecosystem of cross-device encrypted applications. Before cutting down the proverbial tree, we need a sharper axe.
      </p>
      <h3>Key Advantages of Locality Social Cloud</h3>
      <ul>
        <li><strong>Cross-Platform End-to-End Encryption:</strong> Built-in encryption ensures user privacy across devices.</li>
        <li><strong>Intuitive State Management:</strong> Rethinking data handling allows for more efficient and decentralized algorithms.</li>
        <li><strong>Accelerated Development:</strong> Frontend-driven state management enhances app development speed.</li>
        <li><strong>Shared Ecosystem:</strong> Encrypted compliance across apps fosters collaboration and user data sharing.</li>
        <li><strong>Cost Efficiency:</strong> Our highly optimized, scalable distributed system allows us to compete aggressively on price.</li>
        <li><strong>Geo Features:</strong> Efficient querying of geo-entities enhances user interaction and engagement.</li>
      </ul>
    </section>

    <section>
      <h2>Frontend-Driven State Management</h2>
      <p>
        Imagine building a chat application where users send messages seamlessly. Traditional methods require a backend, databases, and extensive setup, but with our PubSub architecture, you can manage events like 'message_added' or 'message_seen' effortlessly. This approach handles connection issues gracefully, allowing developers to focus on creating robust applications.
      </p>
      <p>
        Locality Social Cloud revolutionizes how developers approach end-to-end encrypted messaging, enabling rapid creation without the need for complex backend infrastructure.
      </p>
    </section>

    <section>
      <h2>The Business Opportunity</h2>
      <p>
        By strategically choosing the right abstraction level and focusing on efficiency, Locality Social Cloud can undercut competitor pricing while delivering exceptional value. Our platform encourages collaboration among social networks, creating a unique ecosystem with a compelling network effect that is difficult to disrupt.
      </p>
    </section>

    <section>
      <h2>Closing Thoughts</h2>
      <p>
        Our digital world often confuses us, leading to polarization that obscures our shared dreams and aspirations. As developers, we have the power to reshape this landscape and pursue endeavors that align with creating a cleaner, richer, and more meaningful world.
      </p>
      <p>
        The potential for digital communication to play a significant role in achieving this vision is immense. If we collectively wake up from our bad dreams, we can pave the way for a brighter future.
      </p>
      <p>
        Join us on this transformative journey. Together, we can establish a platform that not only addresses the current challenges but also fosters unity and creativity in our digital lives. Thank you for considering this opportunity.
      </p>
    </section>
  </div>
</template>

<script>
export default {
  name: "ForInvestors",
  mounted() {
    // Scroll to the top of the page over 1 second if scrolled down
    if (window.scrollY > 0) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // Smooth scrolling effect
      });
    }
  },
};
</script>

<style scoped>
.for-investors {
  padding: 20px;
  font-family: Arial, sans-serif;
}

h1 {
  text-align: center;
  color: #2c3e50;
}

h2 {
  margin-top: 30px;
  color: #34495e;
}

h3 {
  margin-top: 20px;
  color: #7f8c8d;
}

p {
  line-height: 1.6;
  margin-bottom: 20px;
}

ul {
  list-style-type: disc;
  margin-left: 20px;
  margin-bottom: 20px;
}
</style>
