<template>
    <ServiceLayout
      title="Startup Booster"
      subtitle="Launch your startup with a solid technical foundation that's built to scale"
    >
      <div class="service-grid">
        <div class="feature-card">
          <h2>Core Features</h2>
          <ul>
            <li>Custom mobile/web application development</li>
            <li>Seamless CRM/accounting system integration</li>
            <li>Scalable architecture design</li>
            <li>User-centric interface design</li>
            <li>Performance optimization</li>
          </ul>
        </div>
  
        <div class="feature-card">
          <h2>Implementation Process</h2>
          <div class="timeline">
            <div class="timeline-item">
              <h3>Week 1</h3>
              <p>Requirements gathering and architecture planning</p>
            </div>
            <div class="timeline-item">
              <h3>Week 2</h3>
              <p>Core development and system integration</p>
            </div>
            <div class="timeline-item">
              <h3>Week 3</h3>
              <p>Testing and refinement</p>
            </div>
            <div class="timeline-item">
              <h3>Week 4</h3>
              <p>Deployment and team training</p>
            </div>
          </div>
        </div>
  
        <div class="feature-card pricing">
          <h2>Investment</h2>
          <div class="price-range">€30K - €60K</div>
          <p>Flexible payment options available for funded startups</p>
          <ul>
            <li>Monthly payment plans</li>
            <li>Equity options available</li>
            <li>Success-based pricing models</li>
          </ul>
        </div>
      </div>
    </ServiceLayout>
  </template>
  
  <script>
  import ServiceLayout from '@/components/ServiceLayout.vue'
  
  export default {
    name: 'StartupBooster',
    components: {
      ServiceLayout
    }
  }
  </script>
  