<template>
  <div class="service-page">
    <div class="container mx-auto px-4">
      <header class="service-header">
        <nav class="terminal-breadcrumb">
          <div class="terminal-header">
            <div class="terminal-controls">
              <span class="control close"></span>
              <span class="control minimize"></span>
              <span class="control maximize"></span>
            </div>
            <div class="terminal-title">navigation.sh</div>
          </div>
          <div class="breadcrumb-content">
            <span class="prompt-symbol">$</span>
            <router-link to="/">consulting</router-link>
            <span class="prompt-symbol">/</span>
            <router-link to="/services">services</router-link>
            <span class="prompt-symbol">/</span>
            <span class="current-path">{{ title }}</span>
          </div>
        </nav>

        <div class="title-container">
          <h1 class="title">
            <span class="prompt-symbol">$</span> {{ title }}
          </h1>
          <p class="subtitle">
            <span class="prompt-symbol">></span> {{ subtitle }}
          </p>
        </div>
      </header>
      
      <main class="service-content">
        <slot></slot>
      </main>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ServiceLayout',
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
.service-page {
  @apply min-h-screen bg-slate-900 text-gray-100 py-12;
}

.service-header {
  @apply mb-16;
}

.terminal-breadcrumb {
  @apply max-w-4xl mx-auto mb-12 bg-slate-800 rounded-lg overflow-hidden border border-slate-700;
}

.terminal-header {
  @apply bg-slate-800 p-3 flex items-center border-b border-slate-700;
}

.terminal-controls {
  @apply flex gap-2 mr-4;
}

.control {
  @apply w-3 h-3 rounded-full;
}

.close { @apply bg-slate-500; }
.minimize { @apply bg-slate-400; }
.maximize { @apply bg-slate-300; }

.terminal-title {
  @apply text-gray-300 font-mono text-sm;
}

.breadcrumb-content {
  @apply p-4 flex items-center gap-2 font-mono text-sm;
}

.breadcrumb-content a {
  @apply text-blue-400 hover:text-blue-300 transition-colors duration-200;
}

.current-path {
  @apply text-blue-200;
}

.prompt-symbol {
  @apply text-blue-400 font-mono;
}

.title-container {
  @apply max-w-4xl mx-auto text-center mt-16;
}

.title {
  @apply text-4xl md:text-5xl font-bold text-white mb-4 flex items-center justify-center gap-4;
}

.subtitle {
  @apply text-xl text-gray-300 flex items-center justify-center gap-2;
}

.service-content {
  @apply max-w-7xl mx-auto;
}

/* Global styles for child components */
:deep(h2) {
  @apply text-2xl font-mono text-blue-300 mb-6;
}

:deep(h3) {
  @apply text-xl font-mono text-blue-200 mb-4;
}

:deep(p) {
  @apply text-gray-300 leading-relaxed mb-4;
}

:deep(ul) {
  @apply space-y-2 mb-4;
}

:deep(li) {
  @apply text-gray-300;
}

:deep(.terminal-card) {
  @apply bg-slate-800 rounded-lg border border-slate-700 overflow-hidden;
}

:deep(.terminal-content) {
  @apply p-6;
}

@screen md {
  .title {
    @apply text-5xl;
  }
  
  .subtitle {
    @apply text-2xl;
  }
}
</style>