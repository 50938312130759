<template>
  <div class="page-container">
    <div class="container">
      <div class="table-container">
        <v-data-table
          :headers="headers"
          :items="computedApps"
          :footer-props="{'items-per-page-options':[30,60,90]}"
          class="theme-table elevation-10"
        >
          <template v-slot:header="{ props: { headers } }">
            <thead>
              <tr>
                <th v-for="header in headers" :key="header.key">
                  {{ header.title }}
                </th>
              </tr>
            </thead>
          </template>
          <template v-slot:item.app_id="{ item }">
            <td>
              <router-link :to="`/app/${item.app_id}`" class="theme-link">
                {{ item.app_id }}
              </router-link>
            </td>
          </template>
          <template v-slot:item.privacy_policy="{ item }">
            <td>
              <span v-if="item.privacy_policy !== 'No data'">
                <a class="privacy-policy-link" :href="`http://localhost:4000/${item.privacy_policy}`" target="_blank">
                  {{ item.privacy_policy }}
                </a>
                <v-btn
                  class="update-privacy-policy-button"
                  @click="openPrivacyPolicyDialog(item.app_id, item.privacy_policy)"
                >
                  Update Privacy Policy
                </v-btn>
              </span>
              <v-btn
                v-else
                class="add-privacy-policy-button"
                @click="openPrivacyPolicyDialog(item.app_id)"
              >
                Add Privacy Policy
              </v-btn>
            </td>
          </template>

        </v-data-table>
      </div>
      <div class="button-container">
        <v-btn class="add-app-button mt-4" @click="openAppDialog">
          <v-icon dark class="mr-2">mdi-plus</v-icon>
          Add App
        </v-btn>
      </div>
      <v-dialog v-model="appDialog" max-width="500px">
        <v-card class="dialog-card">
          <v-card-title>
            <span class="headline">Create App</span>
          </v-card-title>
          <v-card-text>
            <v-form>
              <v-text-field v-model="newAppName" label="App Name" required />
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="dialog-button" text @click="closeAppDialog">
              Cancel
            </v-btn>
            <v-btn class="dialog-button" text @click="submitCreateApp">
              Create
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="privacyPolicyDialog" max-width="500px">
        <v-card class="dialog-card">
          <v-card-title>
            <span class="headline">{{ currentPrivacyPolicy ? 'Update Privacy Policy' : 'Add Privacy Policy' }}</span>
          </v-card-title>
          <v-card-text>
            <v-form>
              <v-file-input
                v-model="privacyPolicyFile"
                label="Upload Privacy Policy"
                accept=".pdf, .txt"
                required
              ></v-file-input>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="dialog-button" text @click="closePrivacyPolicyDialog">
              Cancel
            </v-btn>
            <v-btn class="dialog-button" text @click="submitPrivacyPolicy">
              {{ currentPrivacyPolicy ? 'Update' : 'Add' }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: "EventsView",
  data() {
    return {
      appDialog: false,
      privacyPolicyDialog: false,
      newAppName: '',
      privacyPolicyFile: null,
      currentAppId: null,
      currentPrivacyPolicy: null,
      headers: [], // Initialize as an empty array
    };
  },
  computed: {
    ...mapState({
      apps: (state) => state.apps,
    }),
    computedApps() {
      if (!this.apps || this.apps.length === 0) {
        return [
          {
            app_id: 'No data',
            app_secret: 'No data',
            eventcount: 0,
            privacy_policy: 'No data',
          },
        ];
      }

      const simulatedEventCount = 150;

      return this.apps.map((app) => ({
        ...app,
        eventcount: simulatedEventCount,
        privacy_policy: app.privacy_policy || 'No data',
        // Exclude company_id here
      }));
    },
  },
  watch: {
    apps: {
      immediate: true,
      handler(newApps) {
        this.fetchEventsSummary(newApps);
        this.generateHeaders(newApps);
      },
    },
  },
  methods: {
    ...mapActions(['listApps', 'createApp']),
    openAppDialog() {
      this.appDialog = true;
    },
    closeAppDialog() {
      this.appDialog = false;
      this.newAppName = '';
    },
    async addPrivacyPolicy({ appId, privacyPolicyFile }) {
      try {
        const formData = new FormData();
        formData.append('app_id', appId);
        formData.append('privacy_policy', privacyPolicyFile);

        // Simulate an API call to upload the privacy policy
        const response = await fetch(this.$store.state.url + '/upload-privacy-policy', {
          method: 'POST',
          body: formData,
        });

        if (!response.ok) {
          throw new Error('Failed to upload privacy policy');
        }

        const data = await response.json();
        console.log('Privacy policy uploaded successfully', data);
        
        // Optionally, you could update the state with the new data here.
      } catch (error) {
        console.error('Error uploading privacy policy:', error);
      }
    },
    async submitCreateApp() {
      if (this.newAppName) {
        await this.createApp(this.newAppName);
        this.closeAppDialog();
        this.listApps();
      }
    },
    openPrivacyPolicyDialog(appId, existingPolicy = null) {
      this.currentAppId = appId;
      this.currentPrivacyPolicy = existingPolicy;
      this.privacyPolicyDialog = true;
    },
    closePrivacyPolicyDialog() {
      this.privacyPolicyDialog = false;
      this.privacyPolicyFile = null;
      this.currentAppId = null;
      this.currentPrivacyPolicy = null;
    },
    async submitPrivacyPolicy() {
      if (this.privacyPolicyFile && this.currentAppId) {
        await this.addPrivacyPolicy({
          appId: this.currentAppId,
          privacyPolicyFile: this.privacyPolicyFile,
        });
        this.closePrivacyPolicyDialog();
        this.listApps();
      }
    },
    generateHeaders(apps) {
      if (apps && apps.length > 0) {
        const sampleApp = apps[0];
        this.headers = Object.keys(sampleApp)
          .filter((key) => key !== 'company_id') // Exclude company_id
          .map((key) => ({
            title: this.formatHeaderText(key),
            key: key,
          }));
        // Add the Privacy Policy column
      }
    },
    formatHeaderText(key) {
      return key
        .split('_')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    },
    async fetchEventsSummary(apps) {
      if (!apps) return;

      const simulatedEventCount = 150;

      this.apps = apps.map((app) => ({
        ...app,
        eventcount: simulatedEventCount,
      }));
    },
  },
  created() {
    this.listApps();
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

.page-container {
  background-color: #03001e;
  color: #fdeff9;
  padding: 20px;
  font-family: 'Poppins', sans-serif;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 16px;
}

.table-container {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 1010px; /* Increased from 960px to 1010px */
  margin: 0 auto;
}

.theme-table {
  background: linear-gradient(135deg, #7303c0 0%, #03001e 100%) !important;
  color: #fdeff9 !important;
  border-radius: 25px !important;
  font-size: 1.1rem;
}

.theme-table thead th {
  background-color: #7303c0 !important;
  color: #fdeff9 !important;
}

.theme-table tbody td {
  color: #fdeff9 !important;
}

.theme-link {
  color: #ec38bc !important;
  text-decoration: none;
}

.theme-link:hover {
  text-decoration: underline;
}

.add-app-button {
  background-color: #7303c0 !important;
  color: #fdeff9 !important;
}

.add-app-button:hover {
  background-color: #ec38bc !important;
  color: #fdeff9 !important;
}

.add-privacy-policy-button {
  background-color: #7303c0 !important;
  color: #fdeff9 !important;
}

.add-privacy-policy-button:hover {
  background-color: #ec38bc !important;
  color: #fdeff9 !important;
}

.dialog-card {
  background-color: #03001e !important;
  color: #fdeff9 !important;
  padding-top: 1px;
  padding-left: 1px;
}

.dialog-button {
  background-color: #03001e !important;
  color: #fdeff9 !important;
}

.dialog-button:hover {
  background-color: #ec38bc !important;
  color: #fdeff9 !important;
}

.v-card-title {
  color: #ec38bc !important;
}

.update-privacy-policy-button {
  background-color: #7303c0 !important; /* Example color */
  color: #fdeff9 !important;
}

.update-privacy-policy-button:hover {
  background-color: #7303c0 !important; /* Example hover color */
  color: #fdeff9 !important;
}

.privacy-policy-link {
  color: white; /* Link color */
  text-decoration: underline; /* Underline the link */
  font-size: 12px;
}

.privacy-policy-link:hover {
  color: #1c86ee; /* Darker shade for hover effect */
  text-decoration: none; /* Remove underline on hover for a subtle effect */
}

</style>
