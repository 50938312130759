class ChaCha20Key {
    constructor(data) {
      this.data = new Uint32Array(8);
      if (Array.isArray(data)) {
        this.fromInts(data);
      } else if (typeof data === 'bigint') {
        this.fromBigInt(data);
      } else if (typeof data === 'string') {
        this.fromString(data);
      }
    }
  
    fromInts(ints) {
      if (ints.length < 8) throw new Error("Need at least 8 integers");
      this.data = new Uint32Array(ints.slice(0, 8));
    }
  
    fromBigInt(bigInt) {
      const bigIntStr = bigInt.toString(32);
      const runes = Array.from(bigIntStr).map((ch) => parseInt(ch, 32));
      this.data = new Uint32Array(runes.slice(0, 8));
    }
  
    fromString(key) {
      const codeUnits = Array.from(key).map((ch) => ch.charCodeAt(0));
      this.fromInts(codeUnits);
    }
  }
  
  class ChaCha20Algorithm {
    static magicConstants = [0x61707865, 0x3320646e, 0x79622d32, 0x6b206574];
  
    constructor(key, blockCounter, nonce) {
      if (key.length !== 8 || nonce.length !== 3) throw new Error("Invalid key or nonce length");
      this.chaChaState = new Uint32Array([
        ...ChaCha20Algorithm.magicConstants,
        ...key,
        blockCounter,
        ...nonce
      ]);
    }
  
    processBytes(inputBytes) {
      const outputBytes = new Uint8Array(inputBytes.length);
      let nextChaChaBytes = new Uint8Array(this.chaChaState.buffer);
      for (let j = 0; j < outputBytes.length; j++) {
        outputBytes[j] = inputBytes[j] ^ nextChaChaBytes[j % 64];
        if (j % 64 === 0) {
          this.chaCha20();
          nextChaChaBytes = new Uint8Array(this.chaChaState.buffer);
        }
      }
      return outputBytes;
    }
  
    chaCha20() {
      const initialStateCopy = new Uint32Array(this.chaChaState);
      this.tenQuarterRounds();
      for (let i = 0; i < this.chaChaState.length; i++) {
        this.chaChaState[i] += initialStateCopy[i];
      }
    }
  
    rotateLeft(n, count) {
      return (n << count) | (n >>> (32 - count));
    }
  
    tenQuarterRounds() {
      for (let i = 0; i < 10; i++) {
        this.quarterRound(0, 4, 8, 12);
        this.quarterRound(1, 5, 9, 13);
        this.quarterRound(2, 6, 10, 14);
        this.quarterRound(3, 7, 11, 15);
        this.quarterRound(0, 5, 10, 15);
        this.quarterRound(1, 6, 11, 12);
        this.quarterRound(2, 7, 8, 13);
        this.quarterRound(3, 4, 9, 14);
      }
    }
  
    quarterRound(a, b, c, d) {
      let result = this.transformFourInputsByChaChaQuarterRound([
        this.chaChaState[a],
        this.chaChaState[b],
        this.chaChaState[c],
        this.chaChaState[d]
      ]);
      this.chaChaState[a] = result[0];
      this.chaChaState[b] = result[1];
      this.chaChaState[c] = result[2];
      this.chaChaState[d] = result[3];
    }
  
    transformFourInputsByChaChaQuarterRound(inputs) {
      inputs[0] += inputs[1];
      inputs[3] ^= inputs[0];
      inputs[3] = this.rotateLeft(inputs[3], 16);
  
      inputs[2] += inputs[3];
      inputs[1] ^= inputs[2];
      inputs[1] = this.rotateLeft(inputs[1], 12);
  
      inputs[0] += inputs[1];
      inputs[3] ^= inputs[0];
      inputs[3] = this.rotateLeft(inputs[3], 8);
  
      inputs[2] += inputs[3];
      inputs[1] ^= inputs[2];
      inputs[1] = this.rotateLeft(inputs[1], 7);
  
      return inputs;
    }
  }
  
  class ChaCha20 {
    constructor(password) {
      this._password = password.data;
    }
  
    decrypt(cipherText, nonce) {
      const chaCha20Algorithm = new ChaCha20Algorithm(this._password, 0, nonce);
      const inputBytes = Uint8Array.from(atob(cipherText), c => c.charCodeAt(0));
      return new TextDecoder().decode(chaCha20Algorithm.processBytes(inputBytes));
    }
  
    encrypt(plainText, nonce) {
      const chaCha20Algorithm = new ChaCha20Algorithm(this._password, 0, nonce);
      const inputBytes = new TextEncoder().encode(plainText);
      return btoa(String.fromCharCode(...chaCha20Algorithm.processBytes(inputBytes)));
    }
  
    encryptMap(data, nonce) {
      return this.encrypt(JSON.stringify(data), nonce);
    }
  
    decryptMap(cipherText, nonce) {
      return JSON.parse(this.decrypt(cipherText, nonce));
    }
  }
  
  module.exports = {
    ChaCha20Key,
    ChaCha20Algorithm,
    ChaCha20
  };