<template>
  <div class="consulting-questions">
    <div class="header mb-20 text-center">
      <h1 class="text-gradient">Discovery Questions</h1>
      <p class="text-muted">
        Tailored questions to ensure we deliver the right solution for your business.<br />
      </p>

    </div>

    <template v-if="filteredQuestions.length">
      <h2 class="text-h5 mb-4 text-gradient">Questions</h2>
      <v-expansion-panels multiple>
        <v-expansion-panel
          v-for="(question, index) in filteredQuestions"
          :key="`question-${index}`"
          class="custom-panel"
          lazy
        >
          <v-expansion-panel-title class="panel-title">
            {{ question.title }}
          </v-expansion-panel-title>
          <v-expansion-panel-text class="panel-text">
            <p class="text-muted mb-4">{{ question.description }}</p>
            <v-textarea
              v-model="answers[question.title]"
              placeholder="Your answer here..."
              variant="outlined"
              class="answer-input"
              rows="4"
              hide-details
            ></v-textarea>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>
  </div>
</template>

<script>
export default {
  name: 'ConsultingQuestions',
  data() {
    return {
      selectedTier: 'kmu',
      answers: {},
      consultingQuestions: [
        {
          title: "What is your organization's digital transformation vision?",
          description: "Understanding your long-term digital strategy helps align solutions with business evolution.",
          relevantTiers: ["startup", "kmu", "enterprise"]
        },
        {
          title: "What is your expected ROI timeframe for this investment?",
          description: "Aligning investment expectations with solution delivery timelines.",
          relevantTiers: ["startup", "kmu", "enterprise"]
        },
        {
          title: "How does this project align with your market positioning?",
          description: "Ensuring the solution strengthens your competitive advantage.",
          relevantTiers: ["startup", "kmu", "enterprise"]
        },
        {
          title: "What are your growth projections for the next 3-5 years?",
          description: "Planning scalability and future-proofing the solution.",
          relevantTiers: ["startup", "kmu", "enterprise"]
        },
        {
          title: "What regulatory compliance requirements affect your operations?",
          description: "Ensuring solutions meet current and anticipated regulatory standards.",
          relevantTiers: ["kmu", "enterprise"]
        },
        {
          title: "What is your current technical architecture?",
          description: "Understanding your technology landscape for optimal integration.",
          relevantTiers: ["kmu", "enterprise"]
        },
        {
          title: "What are your data governance requirements?",
          description: "Ensuring compliance with data handling and privacy standards.",
          relevantTiers: ["kmu", "enterprise"]
        },
        {
          title: "What is your preferred development methodology?",
          description: "Aligning development processes with your organization's practices.",
          relevantTiers: ["startup", "kmu", "enterprise"]
        },
        {
          title: "What are your scaling requirements for the next 24 months?",
          description: "Planning infrastructure for projected growth.",
          relevantTiers: ["startup", "kmu"]
        },
        {
          title: "What is your preferred implementation timeline?",
          description: "Aligning delivery with business objectives and resource availability.",
          relevantTiers: ["startup", "kmu", "enterprise"]
        },
        {
          title: "How do you handle change management?",
          description: "Planning user adoption and organizational change strategies.",
          relevantTiers: ["kmu", "enterprise"]
        },
        {
          title: "What are your training and documentation requirements?",
          description: "Ensuring successful knowledge transfer and user adoption.",
          relevantTiers: ["startup", "kmu", "enterprise"]
        },
        {
          title: "What is your product-market fit validation strategy?",
          description: "Ensuring solution aligns with market validation goals.",
          relevantTiers: ["startup"]
        },
        {
          title: "What are your must-have vs. nice-to-have features?",
          description: "Prioritizing features for MVP and future iterations.",
          relevantTiers: ["startup"]
        },
        {
          title: "What departments will be affected by this implementation?",
          description: "Planning cross-departmental integration and training needs.",
          relevantTiers: ["kmu"]
        },
        {
          title: "What are your data migration requirements?",
          description: "Planning safe and efficient data transition.",
          relevantTiers: ["kmu"]
        },
        {
          title: "What are your global compliance requirements?",
          description: "Ensuring adherence to international regulations.",
          relevantTiers: ["enterprise"]
        },
        {
          title: "How do you handle multi-region data sovereignty?",
          description: "Planning compliant data handling across regions.",
          relevantTiers: ["enterprise"]
        }
      ]
    };
  },
  computed: {
    filteredQuestions() {
      return this.consultingQuestions.filter(q => q.relevantTiers.includes(this.selectedTier));
    }
  }
};
</script>

<style scoped>
.consulting-questions {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.text-gradient {
  background: linear-gradient(to right, #f6356f, #ff5f6d);
  -webkit-background-clip: text;
  color: transparent;
}

.text-muted {
  color: #9ca3af;
}

.header {
  margin-bottom: 40px;
}

.btn-group {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.btn-category {
  background-color: #f1f1f1;
  color: #333;
  text-transform: uppercase;
  font-weight: 500;
}

.custom-panel {
  background-color: #333;
  border-radius: 6px;
}

.panel-title {
  color: #fff;
}

.panel-text {
  padding: 10px;
  color: #bbb;
}

.answer-input {
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  border-radius: 5px;
}

.v-expansion-panel {
  transition: max-height 0.3s ease-out;
}
</style>
