<template>
  <div class="locality-consulting-page">
    <div class="content">
      <h2>We develop the app that unifies your business</h2>
      <p>
        Durch ein hoch skalierbares Erlang Backend werden massiv Kosten gespart.<br>
        Dadurch kann auf alternative Geschäftsmodelle zurückgegriffen werden als ein Werbemodell.
      </p>
      <div class="grid-container">
        <div v-for="(service, index) in services2" :key="index" class="grid-item lower">
          <h3>{{ service.title }}</h3>
          <ul class="styled-list">
            <li v-for="(item, itemIndex) in service.items" :key="itemIndex">
              {{ item }}
            </li>
          </ul>
        </div>
      </div>
      <p>
        In der heutigen digitalen Welt ist es entscheidend, dass Ihre Lösungen sowohl effektiv als auch einzigartig sind. Bei Locality Consulting Services glauben wir, dass digitale Werkzeuge so individuell sein sollten wie die Unternehmen, die sie verwenden. Lassen Sie uns gemeinsam das volle Potenzial Ihrer digitalen Präsenz entfalten.
      </p>

      <h2>Unsere Dienstleistungen</h2>
      <div class="grid-container">
        <div v-for="(service, index) in services" :key="index" class="grid-item">
          <h3>{{ service.title }}</h3>
          <ul class="styled-list">
            <li v-for="(item, itemIndex) in service.items" :key="itemIndex">
              {{ item }}
            </li>
          </ul>
        </div>
      </div>
      <p>
        Wir begleiten Sie von der Konzeption bis zum fertigen Projekt.
      </p>


      <h2>Wir kämpfen für ... </h2>
      <div v-for="(industry, index) in industries" :key="index" class="industry-item">
        <h3>{{ industry.title }}</h3>
        <p>{{ industry.content }} {{ industry.moreContent }}</p>
      </div>


      <h2>Mit uns arbeiten heißt ... </h2>
      <div v-for="(value, index) in companyValues" :key="index" class="value-item">
        <h3>{{ value.title }}</h3>
        <p>{{ value.content }} {{ value.moreContent }}</p>
      </div>
    </div>

      
    <Packages />
  </div>
</template>

<script>
  import Packages from '@/components/Packages.vue'; // Adjust the path as necessary
  import ConsultingCallQuestions from '@/components/ConsultingCallQuestions.vue'; // Adjust the path as necessary


export default {
name: 'LocalityConsultingPage',
  components: {
    Packages,
    ConsultingCallQuestions
  },
  data() {
    return {
      services: [
        {
          title: 'Beratung',
          items: [
            'Entwicklung individueller Lösungen',
            'Beratung bei digitalen Strategien',
            'Analyse bestehender Systeme'
          ]
        },
        {
          title: 'Implementierung',
          items: [
            'Professionelle Implementierung',
            'Integration in bestehende Systeme',
            'Schulung und Unterstützung'
          ]
        },
        {
          title: 'Wartung',
          items: [
            'Regelmäßige Wartung und Aktualisierung',
            'Proaktive Fehlerbehebung',
            '24/7-Support'
          ]
        }
      ],
      services2: [
        {
          title: 'Hoch skalierbar',
          items: [
            'Sehr günstige Betriebskosten',
            'LIVE Capabilities'
          ]
        },
        {
          title: 'Cross Platform',
          items: [
            'Cross-Platform Development',
            'Integration with existing systems'
          ]
        },
        {
          title: 'Verschlüsselung',
          items: [
            'Hochsichere Verschlüsselung',
            'GDPR compliant',
            'OpSec',
          ]
        }
      ],
      companyValues: [
        {
          title: "Verstanden werden",
          content: "Ihr Unternehmen ist so einzigartig wie Sie. ",
          moreContent: "Deshalb hören wir Ihnen aufmerksam zu und entwickeln maßgeschneiderte Lösungen, die genau auf Ihre spezifischen Anforderungen zugeschnitten sind."
        },
        {
          title: "HighTech bekommen",
          content: "Wir nutzen die exquisitesten Werkzeuge, aber nicht als Selbstzweck, sondern weil sie uns erlauben die Grenzen dessen zu sprengen, was sie für machbar halten. Eine Beratung mit uns ist gerade deshalb so wichtig, weil wir nur gemeinsam über das nötige Wissen verfügen, wie moderne Hochtechnologie gerade in Ihrem Unternehmen perfekt zum Einsatz kommen kann. Bevor wir Sie angemessen beraten können, müssen wir Ihr Unternehmen VERSTEHEN. Gemeinsam mit Ihnen identifizieren wir dann Möglichkeiten, wie moderne Informationstechnologie in Ihrem Unternehmen perfekt zum Einsatz kommen kann, um Ihrem Unternehmen den critical edge zu geben. Dadurch können Sie ihre Prozesse beschleunigen, entschlacken, verbessern und sich von Ihrer Konkurrenz abheben und in Ihrer Branche eine digital Vorreiterrolle übernehmen.",
          moreContent: ""
        },
        {
          title: "Abenteuer beschreiten",
          content: "Software-Entwicklung ist kein geradliniger Prozess, sondern ein iterativer. Das liegt vor allem auch daran, dass das Ziel beweglich ist und im Laufe der Entwicklung neue WÜnsche auftreten, wenn man sieht, was alles möglich ist. Durch unsere Beratung erfahren Sie, welche Dinge schwer sind zu entwickeln und welche anderen Dinge möglicherweise überraschend einfach. Eine Individualsoftware, eine maßgeschneiderte Lösung für ein Unternehmen zu entwickeln oder sogar eine B2C, das sind keine Dinge für Leute mit Muffensausen, sondern für Leute, die es sich trauen ins Unbekannte zu gehen.",
          moreContent: "Daher legen wir großen Wert darauf, unsere Bemühungen auf die wichtigsten Ziele und Herausforderungen zu konzentrieren."
        },
        {
          title: "Gehalten werden",
          content: "Wir sind für Sie da. Auch langfristig. Wir bleiben innovativ und stark für Sie, damit wir Ihnen ein verlässlicher Partner für das digitale Zeitalter sein können. Wir schauen für Sie voraus, damit Sie im Jetzt bleiben und sich auf Ihr Kerngeschäft konzentrieren können, ohne Sorge, Ihre technologische Vorreiterrolle in der Branche zu verlieren.",
          moreContent: "Daher legen wir großen Wert darauf, unsere Bemühungen auf die wichtigsten Ziele und Herausforderungen zu konzentrieren."
        }
      ],
      
      industries: [
        {
          title: "Digitale Autonomie, Datensicherheit und Verschlüsselung",
          content: "Durch unsere Fach-Artikel, Aufklärungsarbeit über Hintertüren in elliptischen Kurven und falscher Verwendung von AES-256 und zur Verfügung Stellung von public source libraries leisten wir einen Beitrag dafür, dass Verschlüsselung ein durch Technologe garantiertes Menschenrecht ist und bleibt. Wir kämpfen dafür, Abhängigkeiten von wenigen Big Playern zu reduzieren, kritische Infrastruktur vor Cyber-Attacken zu schützen und vertrauensvolle Kommunikation zu gewährleisten."
        },
        {
          title: "Ein digitales Deutschland",
          content: "Wir bieten unsere Consulting-Services nur in Deutschland an, damit das Land nicht den digitalen Anschluss verliert. Wir betteln darum, die öffentliche Verwaltung digitalisieren zu dürfen, weil wir wissen, wie es geht und weil wir die Bürokratie nicht mehr ertragen."
        },
      ]

    };
  },
  metaInfo() {
    return {
      title: 'Locality Consulting Services - Maßgeschneiderte digitale Lösungen',
      meta: [
        { name: 'description', content: 'Locality Consulting Services bietet individuelle Beratung, Implementierung und Wartung für Ihre digitalen Herausforderungen. Entdecken Sie unsere maßgeschneiderten Lösungen.' },
        { name: 'keywords', content: 'Locality Consulting, IT Beratung, digitale Strategie, Systemarchitektur, Social Cloud, Erlang Backend' }
      ]
    }
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');

.locality-consulting-page {
  background: linear-gradient(to bottom, #414345, #414345 10%);
  color: #bbb1b8;
  padding: 20px;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  line-height: 1.6;
}

.content {
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 60px;
}

h1 {
  font-size: 48px;
  color: #bbb1b8;
  margin-bottom: 40px;
}

h2 {
  font-size: 36px;
  color: #bbb1b8;
  margin-top: 60px;
  margin-bottom: 30px;
}

h3 {
  font-size: 24px;
  color: #bbb1b8;
  margin-bottom: 15px;
}

p {
  margin-bottom: 20px;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-bottom: 30px;
}

/* Add transparency, gradient background, and blur effect to each card */
.grid-item {
  padding: 20px;
  border-radius: 0px;
  color: white;
  backdrop-filter: blur(20px); /* Blur effect */
  background-color: rgba(255, 255, 255, 0.1); /* Transparency */
}

/* Radial gradient backgrounds for different cards */
.grid-item:nth-child(1) {
  background: radial-gradient(circle, rgba(255, 182, 193, 0.3), transparent);
}

.grid-item:nth-child(2) {
  background: radial-gradient(circle, rgba(57, 255, 20, 0.3), transparent);
}

.grid-item:nth-child(3) {
  background: radial-gradient(circle, rgba(173, 216, 230, 0.3), transparent);
}
.lower:nth-child(1) {
  background: radial-gradient(circle, rgba(172, 29, 160, 0.61), transparent);
}
.lower:nth-child(2) {
  background: radial-gradient(circle, rgba(230, 226, 24, 0.3), transparent);
}
.lower:nth-child(3) {
  background: radial-gradient(circle, rgba(24, 150, 223, 0.3), transparent);
}

.styled-list {
  list-style-type: disc;
  padding-left: 20px;
}

.styled-list li {
  margin-top: 10px;
}

.industry-item, .value-item {
  margin-bottom: 30px;
}

.cta-section {
  text-align: center;
  margin-top: 80px;
}

.cta-button {
  display: inline-block;
  padding: 24px 48px;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  border-radius: 6px;
  transition: all 0.3s ease;
  background-color: #ec38bcaa;
  margin-top: 60px;
  margin-bottom: 60px;
  color: white;
}

.cta-button:hover {
  background-color: #a00075;
}
.custom-buttonx:hover {
  background-color: #ec38bc77;
  color: rgb(244, 248, 10);
}

.custom-button {
  display: inline-block;
  padding: 24px;
  background-color: rgb(255, 251, 3);
  color: black;
  border-radius: 8px;
  text-decoration: none;
  transition: background-color 0.3s ease;
  margin-bottom: 100px;
  margin-top: 60px;
}
.custom-buttonx {
  margin-top: 40px;
  display: absolute;
  padding: 24px;
  background-color: #ffffff22;
  color: black;
  position: absolute;
  width: 400px;
  height: 100px;
  transform: translateX(-330px);
  border-radius: 8px;
  text-decoration: none;
  backdrop-filter: blur(40px);
  transition: background-color 0.3s ease;
}

/* Media Queries for Mobile Devices */
@media (max-width: 768px) {
  .locality-consulting-page {
    padding: 10px;
  }

  .content {
    padding-top: 30px;
  }

  h1 {
    font-size: 36px;
  }

  h2 {
    font-size: 28px;
  }

  h3 {
    font-size: 20px;
  }

  .grid-container {
    grid-template-columns: 1fr;
  }

  .cta-button {
    font-size: 16px;
    padding: 10px 20px;
  }
}

</style>


