<template>
    <div>
      <Header activeTab="consulting" :isOpaque="false" />
      <Header activeTab="consulting" :isOpaque="false" />
      <Consulting />
      <Footer />
    </div>
  </template>
  
  <script>
  import Header from '@/components/HeaderComponent.vue';
  import Consulting from '@/views/Consulting.vue';
  import Footer from '../components/Footer.vue';
  
  export default {
    name: 'ConsultingPage',
    components: {
      Header,
      Consulting,
      Footer
    }
  };
  </script>
  
  <style>
  .DOCU {
    color:red;
  }
  .subheader {
    height: 60px;
    width: 100%;
    background-color: black;
    position: fixed;
  }
  /* You can add some styles here if needed */
  </style>
  