<template>
    <div class="bg-gray-800 rounded-xl shadow-lg border border-gray-700 overflow-hidden">
      <div class="grid grid-cols-1 lg:grid-cols-3 gap-6">
        <!-- Case Description -->
        <div class="p-6">
          <div class="flex items-center mb-4">
            <div :class="`w-12 h-12 ${iconBgClass} rounded-full flex items-center justify-center`">
              <i :class="`${icon} ${iconColorClass} text-xl`"></i>
            </div>
            <h3 class="text-xl font-bold text-white ml-4">{{ title }}</h3>
          </div>
          <p class="text-gray-300 mb-4">{{ description }}</p>
          <ul class="space-y-2 text-gray-400">
            <li v-for="highlight in highlights" :key="highlight" class="flex items-center">
              <i class="fas fa-check text-green-400 mr-2"></i>{{ highlight }}
            </li>
          </ul>
        </div>
  
        <!-- Interactive Demo -->
        <div class="lg:col-span-2 bg-gray-900 p-6">
          <div class="space-y-4">
            <!-- Optional Alert -->
            <div v-if="alert" class="flex justify-between items-center mb-4">
              <h4 class="text-white font-semibold">{{ chartTitle }}</h4>
              <span :class="alertClass">
                <i :class="`${alertIcon} mr-1`"></i>
                {{ alert }}
              </span>
            </div>
  
            <!-- Chart Component -->
            <div class="h-48">
              <component :is="chartType" :data="chartData" />
            </div>
  
            <!-- Metrics -->
            <div v-if="metrics" class="grid grid-cols-3 gap-4">
              <div v-for="metric in metrics" :key="metric.title" class="bg-gray-800 rounded-lg p-4">
                <div class="text-sm text-gray-400">{{ metric.title }}</div>
                <div :class="metric.color" class="text-xl font-bold">{{ metric.value }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'UseCaseCard',
    props: {
      icon: String,
      iconBgClass: String,
      iconColorClass: String,
      title: String,
      description: String,
      highlights: Array,
      metrics: Array,
      chartData: Array,
      chartType: String,
      chartTitle: String,
      alert: String,
      alertIcon: String,
      alertClass: String
    }
  }
  </script>
  
  <style scoped>
  /* Additional styles if needed */
  </style>
  