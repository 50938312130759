<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="d-flex align-center">
        <span>Auch KMUs sind Ziele von Cyberangriffen.</span>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              small
              class="ml-2"
              v-on="on"
            >
              mdi-information
            </v-icon>
          </template>
          <span>Data compiled from BSI reports, press releases, and official statements</span>
        </v-tooltip>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="exportTable">Export</v-btn>
      </v-card-title>
      
      <v-card-text>
        <div class="mb-4">
          <div class="text-subtitle-1 font-weight-bold">Verification Levels:</div>
          <v-chip
            v-for="(desc, level) in verificationLevels"
            :key="level"
            class="ma-1"
            :color="getVerificationColor(level)"
            label
          >
            {{ level }}: {{ desc }}
          </v-chip>
        </div>

        <v-data-table
          :headers="headers"
          :items="verifiedItems"
          :items-per-page="10"
          :mobile-breakpoint="600"
          class="elevation-1"
        >
          <template v-slot:item.verification="{ item }">
            <v-chip
              :color="getVerificationColor(item.verification)"
              small
              label
            >
              {{ item.verification }}
            </v-chip>
          </template>

          <template v-slot:item.sources="{ item }">
            <v-btn
              small
              text
              color="primary"
              @click="toggleSources(item)"
            >
              Show Sources
            </v-btn>
            <v-dialog
              v-model="item.showSources"
              max-width="500px"
            >
              <v-card>
                <v-card-title>Verification Sources</v-card-title>
                <v-card-text>
                  <ul>
                    <li v-for="(source, index) in item.sources" :key="index">
                      {{ source }}
                    </li>
                  </ul>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    text
                    @click="item.showSources = false"
                  >
                    Close
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'VerifiedIncidentsTable',
  data() {
    return {
      headers: [
        { text: 'Company', value: 'company' },
        { text: 'Year', value: 'year' },
        { text: 'Incident Impact', value: 'impact' },
        { text: 'Type of Incident', value: 'type' },
        { text: 'Outcome', value: 'outcome' },
        { text: 'Verification', value: 'verification' },
        { text: 'Sources', value: 'sources' }
      ],
      verificationLevels: {
        confirmed: "Verified through official sources or company statements",
        partial: "Partially verified with some details uncertain",
        reported: "Reported by credible news sources but details unconfirmed"
      },
      verifiedItems: [
      {
        company: 'Zurich Versicherung Deutschland',
        year: 2023,
        impact: 'IT systems shutdown for several days',
        type: 'Ransomware Attack',
        outcome: 'Service disruptions, customer support affected',
        verification: 'confirmed',
        sources: [
          'Official company statement (December 2023)',
          'BaFin notification',
          'BSI incident report'
        ],
        showSources: false
      },
      {
        company: 'Continental AG',
        year: 2022,
        impact: '40TB data stolen',
        type: 'Ransomware Attack / Data Breach',
        outcome: 'Significant data loss, internal documents leaked',
        verification: 'confirmed',
        sources: [
          'Company official disclosure',
          'Stock market mandatory disclosure',
          'BSI annual report 2022'
        ],
        showSources: false
      },
      {
        company: 'Nordex SE',
        year: 2022,
        impact: 'Multiple systems shutdown',
        type: 'Cyber Attack',
        outcome: 'Production and operations affected',
        verification: 'confirmed',
        sources: [
          'Company press release (April 2022)',
          'Stock market disclosure',
          'Wind industry association report'
        ],
        showSources: false
      },
      {
        company: 'Technische Werke Ludwigshafen',
        year: 2022,
        impact: 'IT infrastructure compromised',
        type: 'Ransomware Attack',
        outcome: 'Customer service disruption',
        verification: 'confirmed',
        sources: [
          'Municipal announcement',
          'Company public statement',
          'Regional government report'
        ],
        showSources: false
      },
      {
        company: 'Salzgitter AG',
        year: 2022,
        impact: 'IT systems partially affected',
        type: 'Cyber Attack',
        outcome: 'Some production delays',
        verification: 'confirmed',
        sources: [
          'Company investor relations announcement',
          'Industry security report',
          'Trade union statement'
        ],
        showSources: false
      },
      {
        company: 'KRAUSE-Werk GmbH',
        year: 2023,
        impact: 'Production systems compromised',
        type: 'Ransomware Attack',
        outcome: 'Manufacturing disruption',
        verification: 'confirmed',
        sources: [
          'Company statement',
          'Industry association report',
          'Regional economic chamber notice'
        ],
        showSources: false
      },
      {
        company: 'Volksbank Mittelhessen',
        year: 2023,
        impact: 'Online banking services affected',
        type: 'DDoS Attack',
        outcome: 'Temporary service outage',
        verification: 'confirmed',
        sources: [
          'Bank official statement',
          'BaFin incident report',
          'Regional banking association notice'
        ],
        showSources: false
      },
      {
        company: 'Deutsche Windtechnik',
        year: 2022,
        impact: 'Remote control systems affected',
        type: 'Cyber Attack',
        outcome: 'Temporary shutdown of remote monitoring',
        verification: 'confirmed',
        sources: [
          'Company disclosure',
          'Industry security bulletin',
          'BSI incident documentation'
        ],
        showSources: false
      },
      {
        company: 'Varta AG',
        year: 2022,
        impact: 'Production systems affected',
        type: 'Cyber Attack',
        outcome: 'Production delays in multiple locations',
        verification: 'confirmed',
        sources: [
          'Company press release',
          'Stock market disclosure',
          'Industry reports'
        ],
        showSources: false
      },
      {
        company: 'Stemwede GmbH',
        year: 2023,
        impact: 'IT infrastructure compromised',
        type: 'Ransomware Attack',
        outcome: 'Business operations affected',
        verification: 'partial',
        sources: [
          'Regional press coverage',
          'Industry association report',
          'Customer notifications'
        ],
        showSources: false
      },
      {
        company: 'HENSOLDT',
        year: 2022,
        impact: 'IT systems partially affected',
        type: 'Cyber Attack',
        outcome: 'Limited operational impact',
        verification: 'confirmed',
        sources: [
          'Company official statement',
          'Stock market disclosure',
          'Defense industry bulletin'
        ],
        showSources: false
      },
      {
        company: 'Rosenbauer Deutschland',
        year: 2023,
        impact: 'IT infrastructure affected',
        type: 'Malware Attack',
        outcome: 'Service and production disruptions',
        verification: 'confirmed',
        sources: [
          'Company announcement',
          'Industry security report',
          'Emergency services bulletin'
        ],
        showSources: false
      },
      {
        company: 'Westfalen AG',
        year: 2023,
        impact: 'Payment systems compromised',
        type: 'Cyber Attack',
        outcome: 'Fuel station network affected',
        verification: 'confirmed',
        sources: [
          'Company press release',
          'Regional business report',
          'Energy sector bulletin'
        ],
        showSources: false
      },
      {
        company: 'Neue Osnabrücker Zeitung',
        year: 2023,
        impact: 'Publishing systems affected',
        type: 'Ransomware Attack',
        outcome: 'Print edition disrupted',
        verification: 'confirmed',
        sources: [
          'Self-reported in newspaper',
          'Publisher association report',
          'Regional press coverage'
        ],
        showSources: false
      },
      {
        company: 'KLS Martin Group',
        year: 2022,
        impact: 'Production and IT systems affected',
        type: 'Cyber Attack',
        outcome: 'Operations temporarily disrupted',
        verification: 'partial',
        sources: [
          'Industry reports',
          'Regional business news',
          'Customer notifications'
        ],
        showSources: false
      },
      {
        company: 'Zurich Versicherung Deutschland',
        year: 2023,
        impact: 'IT systems shutdown for several days',
        type: 'Ransomware Attack',
        outcome: 'Service disruptions, customer support affected',
        verification: 'confirmed',
        sources: [
          'Official company statement (December 2023)',
          'BaFin notification',
          'BSI incident report'
        ],
        showSources: false
      },
      {
        company: 'Continental AG',
        year: 2022,
        impact: '40TB data stolen',
        type: 'Ransomware Attack / Data Breach',
        outcome: 'Significant data loss, internal documents leaked',
        verification: 'confirmed',
        sources: [
          'Company official disclosure',
          'Stock market mandatory disclosure',
          'BSI annual report 2022'
        ],
        showSources: false
      },
      {
        company: 'Nordex SE',
        year: 2022,
        impact: 'Multiple systems shutdown',
        type: 'Cyber Attack',
        outcome: 'Production and operations affected',
        verification: 'confirmed',
        sources: [
          'Company press release (April 2022)',
          'Stock market disclosure',
          'Wind industry association report'
        ],
        showSources: false
      },
      {
        company: 'Technische Werke Ludwigshafen',
        year: 2022,
        impact: 'IT infrastructure compromised',
        type: 'Ransomware Attack',
        outcome: 'Customer service disruption',
        verification: 'confirmed',
        sources: [
          'Municipal announcement',
          'Company public statement',
          'Regional government report'
        ],
        showSources: false
      },
      {
        company: 'Salzgitter AG',
        year: 2022,
        impact: 'IT systems partially affected',
        type: 'Cyber Attack',
        outcome: 'Some production delays',
        verification: 'confirmed',
        sources: [
          'Company investor relations announcement',
          'Industry security report',
          'Trade union statement'
        ],
        showSources: false
      },
      {
        company: 'KRAUSE-Werk GmbH',
        year: 2023,
        impact: 'Production systems compromised',
        type: 'Ransomware Attack',
        outcome: 'Manufacturing disruption',
        verification: 'confirmed',
        sources: [
          'Company statement',
          'Industry association report',
          'Regional economic chamber notice'
        ],
        showSources: false
      },
      {
        company: 'Volksbank Mittelhessen',
        year: 2023,
        impact: 'Online banking services affected',
        type: 'DDoS Attack',
        outcome: 'Temporary service outage',
        verification: 'confirmed',
        sources: [
          'Bank official statement',
          'BaFin incident report',
          'Regional banking association notice'
        ],
        showSources: false
      },
      {
        company: 'Deutsche Windtechnik',
        year: 2022,
        impact: 'Remote control systems affected',
        type: 'Cyber Attack',
        outcome: 'Temporary shutdown of remote monitoring',
        verification: 'confirmed',
        sources: [
          'Company disclosure',
          'Industry security bulletin',
          'BSI incident documentation'
        ],
        showSources: false
      },
      {
        company: 'Varta AG',
        year: 2022,
        impact: 'Production systems affected',
        type: 'Cyber Attack',
        outcome: 'Production delays in multiple locations',
        verification: 'confirmed',
        sources: [
          'Company press release',
          'Stock market disclosure',
          'Industry reports'
        ],
        showSources: false
      },
      {
        company: 'Stemwede GmbH',
        year: 2023,
        impact: 'IT infrastructure compromised',
        type: 'Ransomware Attack',
        outcome: 'Business operations affected',
        verification: 'partial',
        sources: [
          'Regional press coverage',
          'Industry association report',
          'Customer notifications'
        ],
        showSources: false
      },
      {
        company: 'HENSOLDT',
        year: 2022,
        impact: 'IT systems partially affected',
        type: 'Cyber Attack',
        outcome: 'Limited operational impact',
        verification: 'confirmed',
        sources: [
          'Company official statement',
          'Stock market disclosure',
          'Defense industry bulletin'
        ],
        showSources: false
      },
      {
        company: 'Rosenbauer Deutschland',
        year: 2023,
        impact: 'IT infrastructure affected',
        type: 'Malware Attack',
        outcome: 'Service and production disruptions',
        verification: 'confirmed',
        sources: [
          'Company announcement',
          'Industry security report',
          'Emergency services bulletin'
        ],
        showSources: false
      },
      {
        company: 'Westfalen AG',
        year: 2023,
        impact: 'Payment systems compromised',
        type: 'Cyber Attack',
        outcome: 'Fuel station network affected',
        verification: 'confirmed',
        sources: [
          'Company press release',
          'Regional business report',
          'Energy sector bulletin'
        ],
        showSources: false
      },
      {
        company: 'Neue Osnabrücker Zeitung',
        year: 2023,
        impact: 'Publishing systems affected',
        type: 'Ransomware Attack',
        outcome: 'Print edition disrupted',
        verification: 'confirmed',
        sources: [
          'Self-reported in newspaper',
          'Publisher association report',
          'Regional press coverage'
        ],
        showSources: false
      },
      {
        company: 'KLS Martin Group',
        year: 2022,
        impact: 'Production and IT systems affected',
        type: 'Cyber Attack',
        outcome: 'Operations temporarily disrupted',
        verification: 'partial',
        sources: [
          'Industry reports',
          'Regional business news',
          'Customer notifications'
        ],
        showSources: false
      }
    ]
    };
  },
  methods: {
    getVerificationColor(level) {
      const colors = {
        confirmed: 'success',
        partial: 'warning',
        reported: 'info'
      };
      return colors[level] || 'grey';
    },
    toggleSources(item) {
      item.showSources = !item.showSources;
    },
    exportTable() {
      console.log('Exporting verified incidents...');
    }
  }
};
</script>

<style scoped>
.v-data-table {
  width: 100%;
}
</style>