import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginRegister from '@/components/LoginRegister.vue';
import Documentation from '@/views/Documentation.vue';
import Setup from '@/views/Setup.vue';
import Apps from '@/views/Apps.vue';
import AdminPage from '@/views/AdminPage.vue';
import OurStory from '@/views/OurStory.vue';
import GDPR from '@/views/GDPR.vue';
import Impressum from '@/views/Impressum.vue';
import DataProtection from '@/views/DataProtection.vue';
import ViewSingleAppPage from '@/views/ViewSingleAppPage.vue';
import Pricing from '@/components/Pricing.vue';
import Contact from '@/views/Contact.vue';

import DocumentationPage from '@/pages/DocumentationPage.vue';
import ConsultingPage from '@/pages/ConsultingPage.vue';
import SetupPage from '@/pages/SetupPage.vue';
import CareersPage from '@/pages/CareersPage.vue';
import ForInvestorsPage from '@/pages/ForInvestorsPage.vue';

import StartupBooster from '@/pages/packages/StartupBooster.vue';
import SocialApp from '@/pages/packages/SocialApp.vue';
import BusinessUnificationSuite from '@/pages/packages/BusinessUnificationSuite.vue';
import PrivateCloud from '@/pages/packages/PrivateCloud.vue';
import CybersecurityAudit from '@/pages/packages/CybersecurityAudit.vue';
import CybersecurityCourse from '@/pages/packages/CybersecurityCourse.vue';
import Dashboard from '@/pages/packages/Dashboard.vue';
import ServicesList from '@/pages/packages/ServicesList.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'Locality Social Cloud',
      metaTags: [
        {
          name: 'Locality Social Cloud',
          content: 'The home page of our example app.'
        },
        {
          property: 'og:description',
          content: 'The home page of our example app.'
        },
      ]
    }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/services',
    name: 'ServicesList',
    component: ServicesList
  },
  {
    path: '/services/cybersecurity',
    name: 'Cybersecurity Audit',
    component: CybersecurityAudit
  },
  {
    path: '/services/private-cloud',
    name: 'Startup Booster',
    component: PrivateCloud
  },
  {
    path: '/services/business-suite',
    name: 'Business Unification Suite',
    component: BusinessUnificationSuite
  },
  {
    path: '/services/startup-booster',
    name: 'Startup Booster',
    component: StartupBooster
  },
  {
    path: '/services/social-app',
    name: 'Social App',
    component: SocialApp
  },
  {
    path: '/services/cybersecurity-course',
    name: 'Cybersecurity Course',
    component: CybersecurityCourse
  },
  {
    path: '/services/dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/gdpr',
    name: 'GDPR',
    component: GDPR
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component: Impressum
  },
  {
    path: '/datenschutz',
    name: 'DataProtection',
    component: DataProtection
  },
  {
    path: '/login',
    name: 'LoginRegister',
    component: LoginRegister
  },
  {
    path: '/documentation',
    name: 'Documentation',
    component: DocumentationPage
  },
  {
    path: '/consulting',
    name: 'Consulting',
    component: ConsultingPage
  },
  {
    path: '/adminpage',
    name: 'AdminPage',
    component: AdminPage
  },
  {
    path: '/setup',
    name: 'SetupPage',
    component: SetupPage
  },
  {
    path: '/apps',
    name: 'Apps',
    component: Apps
  },
  {
    path: '/ourStory',
    name: 'OurStory',
    component: OurStory
  },
  {
    path: '/pricing',
    name: 'Pricing',
    component: Pricing
  },
  {
    path: '/for-investors',
    name: 'ForInvestors',
    component: ForInvestorsPage
  },
  {
    path: '/careers',
    name: 'Careers',
    component: CareersPage
  },
  {
    path: '/app/:app_id',
    name: 'ViewSingleAppPage',
    component: ViewSingleAppPage
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
